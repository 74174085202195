const HandIcon = () => (
  <svg width="53" height="105" viewBox="0 0 53 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.40966 99.9799C-1.92034 94.0001 -0.240339 85.5739 6.04966 81.7282L11.7597 78.245C12.5597 77.7517 12.8997 76.7853 12.6397 75.8792C10.9797 70.0806 19.8597 56.8222 25.1797 48.8893C27.9697 44.7316 30.1697 41.4497 30.9297 39.4363C31.5797 37.7148 32.6597 34.5336 34.0197 30.5068C36.9497 21.849 40.9597 9.98998 43.5197 4.60407C45.1897 1.10072 47.8197 0.466489 49.5797 0.979912C51.5397 1.5638 53.3197 3.78864 52.8397 7.45307C52.2497 12.0034 50.7297 23.0068 48.6597 32.3088C44.8797 31.3524 40.8197 33.0839 38.3997 36.7987C36.0397 40.4128 34.7297 44.9833 33.4697 49.4128C32.2197 53.7819 31.0397 57.9195 29.0997 59.943C28.2197 60.8692 28.3697 62.4195 29.5297 63.1242C30.3597 63.6376 31.4497 63.396 32.1197 62.6712C34.6897 59.9027 35.9897 55.3524 37.3697 50.5403C38.5497 46.4128 39.7697 42.1443 41.7897 39.0437C43.6797 36.1544 46.3097 35.7819 47.9797 36.3256C49.9397 36.9698 51.0397 38.8021 50.9197 41.2282C50.7897 43.9061 49.9797 46.141 49.0497 48.7182C47.8597 52.0202 46.5097 55.7551 46.4397 61.0705C46.3897 64.5437 45.7597 69.396 44.7297 74.2786C42.6097 84.3054 36.2097 92.8927 27.2597 97.8054L17.2297 103.312C12.1497 106.101 5.80966 104.661 2.39966 99.9598L2.40966 99.9799Z"
      fill="white"
    />
  </svg>
)

export default HandIcon
