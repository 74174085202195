const MegaFoon = () => (
  <svg width="205" height="181" viewBox="0 0 205 181" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.896 41.0545L65.8938 55.7259L45.4985 40.4304L40.4318 36.6286L46.9375 36.7503L54.2757 36.8811L40.2975 22.1404L60.6582 37.5107L65.6829 41.3054L59.214 41.1843L51.896 41.0545Z"
      fill="white"
    />
    <path
      d="M41.2352 72.3317L36.7486 73.0596L40.0462 69.7551L43.7691 66.0203L29.1798 65.9183L47.2675 63.031L51.7344 62.3188L48.4564 65.6077L44.7398 69.3288L59.3027 69.3898L41.2352 72.3317Z"
      fill="white"
    />
    <path
      d="M79.1744 28.6755L83.1003 42.7003L75.3306 26.1265L73.3986 22.0074L77.4831 24.276L82.0891 26.8347L78.2024 12.7785L85.9229 29.3874L87.8309 33.4871L83.7701 31.2295L79.1744 28.6755Z"
      fill="white"
    />
    <path
      d="M158.545 150.8C160.459 149.251 163.315 149.572 164.913 151.497C166.517 153.433 166.269 156.257 164.353 157.808L146.408 172.352C144.783 173.667 142.487 173.642 140.848 172.424C140.556 172.207 140.284 171.949 140.04 171.655C138.436 169.719 138.686 166.892 140.6 165.344L158.545 150.8Z"
      fill="white"
    />
    <path
      d="M38.2868 92.8454L109.069 35.4718C110.981 33.9185 113.841 34.2411 115.437 36.1688C117.041 38.1045 116.793 40.9287 114.877 42.4805L44.095 99.8541C42.4733 101.171 40.1735 101.144 38.5347 99.9256C38.2432 99.7086 37.9706 99.4509 37.727 99.1571C36.1227 97.2213 36.3727 94.3937 38.2868 92.8454Z"
      fill="white"
    />
    <path
      d="M111.232 51.0232C112.39 50.0862 114.157 50.535 114.761 51.9227L155.569 145.424C155.968 146.337 155.726 147.388 154.975 148.014L139.475 160.941C138.698 161.588 137.571 161.629 136.723 161.036L127.394 154.512C121.904 161.413 111.728 162.672 104.437 157.242L81.7204 140.322C74.9505 135.281 72.9974 126.198 76.7904 119.132L52.64 102.248C51.399 101.382 51.3096 99.5933 52.4595 98.6649L111.232 51.0232ZM84.3768 136.755L107.093 153.674C112.326 157.573 119.579 156.708 123.619 151.875L80.5532 121.767C78.1097 126.847 79.5853 133.187 84.3768 136.755Z"
      fill="white"
    />
  </svg>
)

export default MegaFoon;