import { gql, useMutation } from '@apollo/client'
import { useGroupWorkoutSessionActions, useCurrentGroupWorkoutSession} from 'store/group-workout-session/selectors';
import { PartialGroupWorkoutSession } from 'interfaces/group-workout'

const CREATE_GROUP_WORKOUT_SESSION = gql`
  mutation customCreateGroupWorkoutSession($input: GroupWorkoutSessionInputSchema!) {
    customCreateGroupWorkoutSession(input: $input) {
      id
      groupWorkout {
        id
      }
      groupBags {
        id
        isEnabled
        bag {
          id
          name
          reference
          rank
          latestWifiStrength
          averageReceivedPackets
          latestRebootCount
        }
      }
      groupWorkoutSessionTracks {
        id
        sortOrder
        groupWorkoutSessionRounds {
          id
          sortOrder
          groupWorkoutHits {
            id
            force
            punchedAt
            bag {
              id
            }
          }
        }
      }
    }
  }
`;

const SAVE_GROUP_WORKOUT_SESSION = gql`
  mutation customSaveGroupWorkoutSession($input: GroupWorkoutSessionInputSchema!) {
    customSaveGroupWorkoutSession(input: $input) {
      id
      groupWorkout {
        id
      }
      groupBags {
        id
        isEnabled
        bag {
          id
          name
          reference
          rank
          latestWifiStrength
          averageReceivedPackets
          latestRebootCount
        }
      }
      groupWorkoutSessionTracks {
        id
        sortOrder
        groupWorkoutSessionRounds {
          id
          sortOrder
          groupWorkoutHits {
            id
            force
            punchedAt
            bag {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * Custom hook to create a group workout session
 * @param groupWorkoutId The ID of the existing GroupWorkout to link to
 * @returns An object with:
 *  - createGroupWorkoutSession: function to invoke the mutation
 *  - loading: boolean indicating if the request is in-flight
 *  - error: any error returned by the mutation
 *  - data: the complete response from the mutation
 */
export const useCreateGroupWorkoutSession = () => {
  const { setGroupWorkoutSessionById, setLoading, setError } = useGroupWorkoutSessionActions();
  useCurrentGroupWorkoutSession();

  const [createSessionMutation, { loading, error, data }] = useMutation(
    CREATE_GROUP_WORKOUT_SESSION
  );

  /**
   * A function to create a GroupWorkoutSession, linking it to the existing groupWorkoutId.
   * You can extend the `input` object (e.g. startDate, capacity, etc.) depending on your schema.
   */
  const createGroupWorkoutSession = async (groupWorkoutId: string) => {
    try {
      const response = await createSessionMutation({
        variables: {
          input: {
            groupWorkout: {
              id: groupWorkoutId
            },  // Link to existing GroupWorkout
          },
        },
      });
      if (response?.data?.customCreateGroupWorkoutSession) {
        setGroupWorkoutSessionById(response?.data?.customCreateGroupWorkoutSession);
      }
    } catch (err) {
      console.error('Error creating group workout session:', err);
      setError('Failed to create group workout session.');
    }
  };

  return {
    createGroupWorkoutSession,
    loading,
    error,
    data,
  };
};

export const useSaveGroupWorkoutSession = () => {
  const { setGroupWorkoutSessionById, setLoading, setError } = useGroupWorkoutSessionActions();
  useCurrentGroupWorkoutSession();

  const [saveSessionMutation, { loading, error, data }] = useMutation(
    SAVE_GROUP_WORKOUT_SESSION
  );

  /**
   * Function to save updates to an existing GroupWorkoutSession.
   * @param sessionData The complete data object to update the session.
   */
  const saveGroupWorkoutSession = async (sessionData : PartialGroupWorkoutSession) => {
    try {
      const response = await saveSessionMutation({
        variables: {
          input: sessionData, // Pass the full session data to update
        },
      });
      if (response?.data?.customSaveGroupWorkoutSession) {
        setGroupWorkoutSessionById(response?.data?.customSaveGroupWorkoutSession);
      }
    } catch (err) {
      console.error('Error saving group workout session:', err);
      setError('Failed to save group workout session.');
    }
  };

  return {
    saveGroupWorkoutSession,
    loading,
    error,
    data,
  };
};
