const BellIcon = ({height, width} : {height: string, width: string}) => (
  <svg width={width} height={height} viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 0C31.5938 0 33 1.40625 33 3V4.875C39.8438 6.1875 45 12.2812 45 19.5V21.2812C45 25.6875 46.5938 30 49.5 33.2812L50.1562 34.0312C51 34.9688 51.1875 36.1875 50.7188 37.3125C50.25 38.3438 49.125 39 48 39H12C10.7812 39 9.65625 38.3438 9.1875 37.3125C8.71875 36.1875 8.90625 34.9688 9.75 34.0312L10.4062 33.2812C13.3125 30 15 25.6875 15 21.2812V19.5C15 12.2812 20.1562 6.1875 27 4.875V3C27 1.40625 28.3125 0 30 0ZM34.2188 46.3125C33.0938 47.4375 31.5938 48 30 48C28.4062 48 26.8125 47.4375 25.6875 46.3125C24.5625 45.1875 24 43.5938 24 42H30H36C36 43.5938 35.3438 45.1875 34.2188 46.3125ZM0 18.75C0 17.5312 0.9375 16.5 2.25 16.5H9.75C10.9688 16.5 12 17.5312 12 18.75C12 20.0625 10.9688 21 9.75 21H2.25C0.9375 21 0 20.0625 0 18.75ZM50.25 16.5H57.75C58.9688 16.5 60 17.5312 60 18.75C60 20.0625 58.9688 21 57.75 21H50.25C48.9375 21 48 20.0625 48 18.75C48 17.5312 48.9375 16.5 50.25 16.5ZM55.9688 2.0625C56.5312 3.1875 56.0625 4.5 54.9375 5.0625L48.9375 8.0625C47.8125 8.625 46.5 8.15625 45.9375 7.03125C45.375 5.90625 45.8438 4.59375 46.9688 4.03125L52.9688 1.03125C54.0938 0.46875 55.4062 0.9375 55.9688 2.0625ZM4.96875 5.0625C3.84375 4.5 3.375 3.1875 3.9375 2.0625C4.5 0.9375 5.8125 0.46875 6.9375 1.03125L12.9375 4.03125C14.0625 4.59375 14.5312 5.90625 13.9688 7.03125C13.4062 8.15625 12.0938 8.625 10.9688 8.0625L4.96875 5.0625Z"
      fill="white"
    />
  </svg>
)

export default BellIcon
