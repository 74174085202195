import './group-quote.scss'

interface GroupQuoteProps {
  quote: string
}

const GroupQuote: React.FC<GroupQuoteProps> = ({ quote }) => {
  const processedQuote = quote.replace(/\\n/g, '\n');
  return (
    <div
      className="quote-background"
      style={{ width: '80%', height: '100%', textAlign: 'left' }}
    >
      <h1
        style={{
          position: 'relative',
          top: '25%',
          left: '10%',
          lineHeight: "76.8px",
          fontWeight: 900,
          fontSize: "64px",
          letterSpacing: "2%"
        }}
      >
        {processedQuote.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </h1>
    </div>
  )
}

export default GroupQuote
