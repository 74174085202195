// downloadFile.ts

/**
 * Downloads a file from the specified URL and returns a blob URL.
 *
 * @param downloadLocation - The URL from which to download the file.
 * @returns An object containing the blob URL and a revoke function, or null if download is skipped.
 * @throws Will throw an error if the download fails.
 */
export async function downloadFileWithProgress(
  downloadLocation: string,
  onProgress: (progress: number) => void
): Promise<{ blobUrl: string; revoke: () => void } | null> {
  try {
    let token = localStorage.getItem('NEXTROUND_API') || '';
    if (token && JSON.parse(token)) {
      token = JSON.parse(token);
      token = token.toLowerCase().startsWith('basic') ? token : `Basic ${token}`;
    }

    const response = await fetch(downloadLocation, {
      headers: {
        Authorization: token,
        'X-Authorization-Method': 'api',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.status} ${response.statusText}`);
    }

    // Get the total size from the response headers if available
    const contentLength = response.headers.get('content-length');
    const total = contentLength ? parseInt(contentLength, 10) : 0;
    let loaded = 0;

    const reader = response.body?.getReader();
    const chunks: Uint8Array[] = [];

    while (true) {
      const { done, value } = await reader!.read();
      if (done) break;
      if (value) {
        chunks.push(value);
        loaded += value.length;
        if (total) {
          const progress = Math.round((loaded / total) * 100);
          onProgress(progress);
        } else {
          // If total size is unknown, you might call onProgress with a placeholder value.
          onProgress(0);
        }
      }
    }

    const blob = new Blob(chunks);
    const objectUrl = URL.createObjectURL(blob);

    return {
      blobUrl: objectUrl,
      revoke: () => {
        URL.revokeObjectURL(objectUrl);
      },
    };
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

// Generic type for objects that have a download location
type Downloadable<T extends string> = Partial<Record<T, string>> & {
  [key: string]: any;
};

type MediaType = 'image' | 'video' | 'audio';

/**
 * Downloads a file and assigns the blob URL to the target key after validating.
 *
 * @template T - The type of the object containing the download key.
 * @template K - The key in the object that holds the download URL.
 * @template V - The key in the object where the blob URL should be assigned.
 *
 * @param obj - The object containing the download location.
 * @param downloadKey - The key in the object where the download URL is stored.
 * @param targetKey - The key in the object where the blob URL should be assigned.
 * @param mediaType - The type of media ('image', 'video', 'audio').
 * @returns A new object with the assigned blob URL or the original if validation fails.
 */
export async function downloadAndAssign<
  T extends Downloadable<K>,
  K extends string,
  V extends string
>(
  obj: T,
  downloadKey: K,
  targetKey: V,
  mediaType: MediaType,
  onProgress?: (progress: number) => void
): Promise<T & Record<V, string | null>> {
  const downloadLocation = obj[downloadKey];
  const existingBlobUrl = obj[targetKey];

  if (typeof downloadLocation === 'string') {
    try {
      let isValid = false;

      if (existingBlobUrl) {
        switch (mediaType) {
          case 'image':
            isValid = await isImageBlobUrlValid(existingBlobUrl);
            break;
          case 'video':
            isValid = await isVideoBlobUrlValid(existingBlobUrl);
            break;
          case 'audio':
            isValid = await isAudioBlobUrlValid(existingBlobUrl);
            break;
          default:
            isValid = false;
        }
      }

      if (!isValid) {
        // If the blob URL is invalid or missing, download the file with progress tracking.
        const downloadResult = await downloadFileWithProgress(
          downloadLocation,
          onProgress || (() => {})
        );
        if (downloadResult && downloadResult.blobUrl) {
          return {
            ...obj,
            [targetKey]: downloadResult.blobUrl,
          };
        }
      } else {
        // The blob URL is still valid so no need to re-download.
        return obj as T & Record<V, string | null>;
      }
    } catch (error) {
      console.error(`Error downloading file from ${downloadLocation}:`, error);
      // Optionally handle the error, e.g. by assigning a default value or rethrowing.
    }
  }

  // Return the original object if downloadLocation is not a string or the download failed.
  return obj as T & Record<V, string | null>;
}

/**
 * Checks if an audio blob URL is valid by attempting to load it.
 *
 * @param blobUrl - The audio blob URL to validate.
 * @returns A promise that resolves to `true` if valid, `false` otherwise.
 */
export async function isAudioBlobUrlValid(blobUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    const audio = document.createElement('audio');

    // Listen for canplay event to confirm it's loaded correctly
    audio.oncanplay = () => resolve(true);
    audio.onerror = () => resolve(false);

    audio.src = blobUrl;
    audio.load();
  });
}

/**
 * Checks if a video blob URL is valid by attempting to load it.
 *
 * @param blobUrl - The video blob URL to validate.
 * @returns A promise that resolves to `true` if valid, `false` otherwise.
 */
export async function isVideoBlobUrlValid(blobUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    // Listen for metadata to confirm it's loaded correctly
    video.onloadedmetadata = () => resolve(true);
    video.onerror = () => resolve(false);

    video.src = blobUrl;
    video.load();
  });
}

/**
 * Checks if an image blob URL is valid by attempting to load it.
 *
 * @param blobUrl - The image blob URL to validate.
 * @returns A promise that resolves to `true` if valid, `false` otherwise.
 */
export async function isImageBlobUrlValid(blobUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);

    img.src = blobUrl;
  });
}
