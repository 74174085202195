import React from 'react'
import { INRWorkoutSessionHit } from 'interfaces/workout'
import { GroupWorkoutHit, GroupBag } from 'interfaces/group-workout'
import {
  BarChart,
  Bar,
  LabelList,
  Cell,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Customized,
} from 'recharts'
import FirstPlaceIcon from './first-place-icon.svg'
import SecondPlaceIcon from './second-place-icon.svg'
import ThirdPlaceIcon from './third-place-icon.svg'
import './group-hit-challenge.scss'

interface GroupHitChallengeProps {
  groupBags: GroupBag[]
  groupWorkoutHits: GroupWorkoutHit[]
}

const baseColors: string[] = [
  '#2C89C8',
  '#70ED99',
  '#FF7733',
  '#7E68AE',
  '#F7D126',
  '#F04D26',
  '#00AFB9',
  '#7857FF',
  '#F9383B',
  '#006BE8',
]

const darkColors: string[] = [
  '#164362',
  '#408757',
  '#99471F',
  '#342B48',
  '#917B16',
  '#8A2C16',
  '#004F53',
  '#483499',
  '#932123',
  '#003C82',
]

const GroupHitChallenge: React.FC<GroupHitChallengeProps> = ({
  groupBags,
  groupWorkoutHits,
}) => {
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id
    if (!acc[bagId]) {
      acc[bagId] = []
    }
    const sessionHit: INRWorkoutSessionHit = {
      angle: 0,
      force: hit.force,
      height: 0,
      punchedAt: hit.punchedAt,
    }
    acc[bagId].push(sessionHit)
    return acc
  }, {} as Record<string, INRWorkoutSessionHit[]>)

  // Map each bag along with its original index, then filter out disabled bags
  const bagIntensities = groupBags
    .map((groupBag, index) => {
      const bagId = groupBag.bag.id
      const hitsForBag = hitsByBag[bagId] || []
      return {
        bagId,
        hitsCount: groupBag.isEnabled ? hitsForBag.length : 0,
        originalIndex: index,
        isEnabled: groupBag.isEnabled,
      }
    })
    .filter((item) => item.isEnabled)

  const sortedData = [...bagIntensities].sort((a, b) => b.hitsCount - a.hitsCount)
  const top3BagIds = sortedData.slice(0, 3).map((item) => item.bagId)

  const renderCustomizedLabel = (props: { x: number; y: number; index: number; width: number }) => {
    const { x, y, index, width } = props
    const bagId = bagIntensities[index].bagId
    const rank = top3BagIds.indexOf(bagId) + 1

    let icon
    switch (rank) {
      case 1:
        icon = FirstPlaceIcon
        break
      case 2:
        icon = SecondPlaceIcon
        break
      case 3:
        icon = ThirdPlaceIcon
        break
      default:
        icon = null
    }

    if (icon && bagIntensities[index].hitsCount > 0) {
      return (
        <g>
          <image
            x={x + width / 2 - 28.5}
            y={y - 57 - 25}
            width={57}
            height={57}
            xlinkHref={icon}
          />
        </g>
      )
    }
    return null
  }

  // Render divider lines based on evenly dividing the chart width by the number of bars.
  // This assumes a band scale where bars are equally spaced.
  const renderDividerLines = (props: any) => {
    const { width, height, offset } = props
    if (!width || !height || !offset) return null

    const left = offset.left || 0
    const top = offset.top || 0
    const right = offset.right || 0
    const bottom = offset.bottom || 0

    const effectiveWidth = width - left - right
    const effectiveHeight = height - top - bottom

    const count = bagIntensities.length
    if (count < 2) return null

    const gap = effectiveWidth / count
    const extension = 50 // Extend 50 pixels on both ends
    const lines = Array.from({ length: count - 1 }, (_: any, i: number) => {
      const dividerX = left + gap * (i + 1)
      return (
        <line
          key={`divider-${i}`}
          x1={dividerX}
          y1={top - extension}
          x2={dividerX}
          y2={top + effectiveHeight + extension}
          stroke="black"
          strokeWidth={1}
        />
      )
    })

    return <g>{lines}</g>
  }

  return (
    <div className="element-group-hit-challenge-wrapper">
      <ResponsiveContainer className="barchart-custom" minHeight={400}>
        <BarChart data={bagIntensities} margin={{ top: 50 }}>
          {/* Hide tick labels but let Recharts compute positions */}
          <XAxis dataKey="bagId" tick={{ fill: 'transparent' }} axisLine={false} />
          <YAxis
            hide={true}
            domain={[0, (dataMax: number) => Math.max(dataMax, 10) * 1.1]}
            tickCount={6}
            tickFormatter={(value) => Math.floor(value).toString()}
            allowDecimals={false}
          />
          <defs>
            {bagIntensities.map(({ bagId, originalIndex }) => (
              <linearGradient key={`gradient-${bagId}`} id={`gradient-${bagId}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="25%" stopColor={baseColors[originalIndex]} />
                <stop offset="100%" stopColor={darkColors[originalIndex]} />
              </linearGradient>
            ))}
          </defs>
          <Bar dataKey="hitsCount" isAnimationActive={false} barSize={80} radius={[10, 10, 0, 0]} maxBarSize={20}>
            {bagIntensities.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#gradient-${entry.bagId})`} />
            ))}
            <LabelList
              dataKey="hitsCount"
              position="insideBottom"
              dy={-10}
              fill="#fff"
              style={{
                fontFamily: 'Canaro',
                fontWeight: 900,
                fontSize: '24px',
                lineHeight: '28.8px',
                letterSpacing: '2%',
                textAlign: 'center',
              }}
            />
            <LabelList dataKey="hitsCount" position="top" fill="#000" content={renderCustomizedLabel as any} />
          </Bar>
          {/* Place the Customized component after the Bar so dividers appear on top */}
          <Customized component={renderDividerLines} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default GroupHitChallenge
