import { useNavigate } from 'react-router-dom'
import home from './home.svg'
import backArrow from './back-arrow.svg'
import volume from './music-volume.svg'
import boxingbag from './boxingbag.svg'
import { ReactNode } from 'react'
import './controls-footer.scss'
interface ControlProps {
  bags: number
  children?: ReactNode
  openBagsPopup: () => void
  onVolumeClick: () => void  // New prop for volume button
}

const ControlsFooter: React.FC<ControlProps> = ({ children, bags, openBagsPopup, onVolumeClick }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <div className="controls" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
      <div style={{ display: 'flex', gap: '2rem' }}>
        <div
          onClick={handleClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            cursor: 'pointer',
            height: '80px',
            width: '80px',
          }}
        >
          <img src={home} alt="home" style={{ height: '2rem' }} />
        </div>
        <div
          onClick={() => window.history.back()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            cursor: 'pointer',
            height: '80px',
            width: '80px',
          }}
        >
          <img src={backArrow} alt="backArrow" style={{ height: '2rem', transform: 'rotate(180deg)' }} />
        </div>
      </div>

      {children}

      <div style={{ display: 'flex', gap: '2rem' }}>
        <div
          onClick={onVolumeClick}  // Use the provided callback to toggle volume slider
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            cursor: 'pointer',
            height: '80px',
            width: '80px',
          }}
        >
          <img src={volume} alt="volume" style={{ height: '2rem' }} />
        </div>
        <div>
            <div
              onClick={openBagsPopup}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'black',
                cursor: 'pointer',
                height: '80px',
                width: '80px',
              }}
            >
              <img src={boxingbag} alt="boxing bag" style={{ height: '2rem' }} />
            </div>
            <div className='boxing-circle-bags'>
              <div className='boxing-circle-bags-text'>
                {bags}
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ControlsFooter
