import './style.scss'
import boxbag from './boxbag.svg'
import backArrow from './back-arrow.svg'
import BackArrow from './back-arrow'

interface ButtonRowProps {
  start: string
  handleSubmit: () => void
  close: string
  closePopup: () => void
  isSetup?: boolean
  isBags?: boolean
  startDisabled? : boolean
  showWhiteSubmit? : boolean
}

const ButtonRow: React.FC<ButtonRowProps> = ({
  start,
  handleSubmit,
  close,
  closePopup,
  isSetup = true,
  isBags = false,
  startDisabled = false,
  showWhiteSubmit = false,
}) => {
  return (
    <div className="flex-style">
      {isBags ? (
        <button onClick={closePopup} className="button-style-bag border-style group-workout-font-family">
          <div className="flex-style item-style" style={{ alignItems: 'center', color: 'black' }}>
            {'Keep going'.toUpperCase()}
            <BackArrow color="black" />
          </div>
        </button>
      ) : (
        <button onClick={closePopup} className="button-style-group border-style group-workout-font-family">
          <div className="flex-style item-style" style={{ alignItems: 'center' }}>
            <img
              src={backArrow}
              alt="backArrow"
              style={{ height: '2rem', transform: 'rotate(180deg)', alignItems: 'center' }}
            />
            {close.toUpperCase()}
          </div>
        </button>
      )}

      {!isBags && (
        <button
          onClick={() => handleSubmit()}
          disabled={startDisabled}
          className={`button-style-group ${showWhiteSubmit ? 'button-white' : 'button-gradient'} group-workout-font-family`}
        >
          {start.toUpperCase()}
          {isSetup ? (
            <img src={boxbag} alt="boxbag" className="boxbag" />
          ) : (
            <img src={backArrow} alt="backArrow" style={{ height: '2rem' }} />
          )}
        </button>
      )}
    </div>
  )
}

export default ButtonRow
