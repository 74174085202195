import { useNavigate } from 'react-router-dom'
import ButtonRow from './button-row'
import { GroupWorkout } from 'interfaces/group-workout'
import './style.scss'
import { useEffect, useRef } from 'react'
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts'
import { useCurrentGroupWorkout, useGroupWorkoutError, useGroupWorkoutLoading } from 'store/group-workout/selectors'
import { useCurrentGroupWorkoutSession } from 'store/group-workout-session/selectors'
import { useCreateGroupWorkoutSession } from 'api/mutations/group-workout-session'
import BagSetup from './bag-setup'

interface PopupProps {
  groupWorkoutProp: GroupWorkout | undefined
  closePopup: () => void
}

const GroupSetup: React.FC<PopupProps> = ({ groupWorkoutProp, closePopup }) => {
  const navigate = useNavigate()
  // Capture the progress values from the query hook.
  const { audioProgress, videoProgress } = useGroupWorkoutByIdQuery(groupWorkoutProp?.id || '')
  
  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading()
  const error = useGroupWorkoutError()
  const hasSessionBeenCreated = useRef(false)
  
  // Access groupWorkouts data from Zustand store via selectors
  const groupWorkout = useCurrentGroupWorkout()
  const groupWorkoutSession = useCurrentGroupWorkoutSession()
  const { createGroupWorkoutSession } = useCreateGroupWorkoutSession()

  const handleCreateSession = async () => {
    try {
      if (groupWorkout) {
        await createGroupWorkoutSession(groupWorkout.id)
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (!loading && groupWorkout && !hasSessionBeenCreated.current) {
      hasSessionBeenCreated.current = true;
      handleCreateSession()
    }
  }, [loading, groupWorkout]);

  const startPreWorkout = () => {
    if (groupWorkoutSession?.id) {
      navigate(`/group-workout/pre-workout/${groupWorkoutSession?.id}`, { state: { id: groupWorkoutSession?.id } })
    }
  };

  return (
    <div className="popupBackground">
      <div className="popupWrapper setup-container" style={{ maxWidth: '80vw', width: 'fit-content' }}>
        {groupWorkoutProp && (
          <div>
            <div className="group-setup-duration">
              {groupWorkoutProp.duration} {'min.'.toUpperCase()}
            </div>
            <div className="group-setup-title" style={{ textAlign: 'center' }}>
              {groupWorkoutProp.name}
            </div>
            <div className="group-setup-subtitle" style={{ textAlign: 'center' }}>
              {groupWorkoutProp.description}
            </div>
          </div>
        )}

        <BagSetup />

        {/* Show progress bars if downloads are still in progress */}
        {(audioProgress < 100 || videoProgress < 100) && (
          <div className="progress-container">
            {audioProgress < 100 && (
              <div className="progress-bar">
                <p>Downloading Audio: {audioProgress}%</p>
                <progress value={audioProgress} max="100"></progress>
              </div>
            )}
            {videoProgress < 100 && (
              <div className="progress-bar">
                <p>Downloading Videos: {videoProgress}%</p>
                <progress value={videoProgress} max="100"></progress>
              </div>
            )}
          </div>
        )}

        <ButtonRow
          close="Back"
          closePopup={closePopup}
          start="Start Training"
          handleSubmit={startPreWorkout}
          // Disable the start button if audio isn't fully downloaded.
          startDisabled={audioProgress < 100}
        />
      </div>
    </div>
  );
};

export default GroupSetup
