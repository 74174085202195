import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import Controls from './controls/controls'
import Main from 'layout/main'
import useControls from '../../hooks/controls'
import { useLocation, useNavigate } from 'react-router-dom'
import GroupWorkoutHeader from 'pages/group-workout/components/header'
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts'
import { useCurrentGroupWorkout, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors'
import { useGroupWorkoutSessionByIdQuery } from 'api/queries/group-workout-session'
import HitSimulate from 'components/hit-simulate/hit-simulate'
import useAddGroupHit from 'lib/hooks/useAddGroupHit'
import {
  useCurrentGroupWorkoutSession,
  useGroupWorkoutSessionActiveTrack,
  useGroupWorkoutSessionActiveRound,
  useGroupWorkoutSessionActions,
} from 'store/group-workout-session/selectors'
import GroupIntensity from 'components/group-workout/group-intensity'
import GroupHitChallenge from 'components/group-workout/group-hit-challenge'
import GroupGroupChallenge from 'components/group-workout/group-group-challenge'
import CircularTimer from 'components/group-workout/circular-timer'
import { SERVER_TIME } from 'api/queries/server-time'
import initApollo from 'api/client'
import { useSaveGroupWorkoutSession } from 'api/mutations/group-workout-session'
import GroupPause from 'components/popup/group-pause/group-pause'
import ControlsFooter from './controls/controls-footer'
import GroupBag from 'components/popup/group-bag/group-bag'
import GroupSwitch from 'components/popup/group-switch/group-switch'
import GroupQuote from 'components/group-workout/group-quote'
import NewTrackList from './header/new-track-list'
import { GroupWorkoutTrackRound } from 'interfaces/group-workout'

const GroupWorkout: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const activeTrack: number = useGroupWorkoutSessionActiveTrack()
  const activeRound: number = useGroupWorkoutSessionActiveRound()

  const { updateActiveRound, updateActiveTrack } = useGroupWorkoutSessionActions()
  const { id } = location.state as { id: string }
  const groupWorkoutSession = useCurrentGroupWorkoutSession()
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null)
  // useEffect(() => {
  //   console.log('groupWorkoutSession updated:', groupWorkoutSession)
  // }, [groupWorkoutSession])

  const { saveGroupWorkoutSession } = useSaveGroupWorkoutSession()
  //console.log(groupWorkoutSession);
  useGroupWorkoutSessionByIdQuery(id, {
    skip: !!groupWorkoutSession && groupWorkoutSession.id === id,
  })
  const groupWorkout = useCurrentGroupWorkout()
  useGroupWorkoutByIdQuery(groupWorkoutSession?.groupWorkout?.id ?? '', {
    skip: !!groupWorkout && !!groupWorkoutSession && groupWorkout.id === groupWorkoutSession?.groupWorkout?.id,
  })
  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading()
  const error = useGroupWorkoutError()

  // Access groupWorkouts data from Zustand store via selectors
  const [rounds, setRounds] = useState<any[]>([])
  useAddGroupHit()

  const [showPausePopup, toggleShowPausePopup] = useState<boolean>(false)
  const [showBagPopup, toggleShowBagPopup] = useState<boolean>(false)
  const [showSwitchPopupExcercise, toggleShowSwitchPopupExcercise] = useState<boolean>(false)
  const [showSwitchPopupEncourage, toggleShowSwitchPopupEncourage] = useState<boolean>(false)

  const sessionTracks = groupWorkoutSession?.groupWorkoutSessionTracks ?? []
  const workoutTracks = groupWorkout?.groupWorkoutTracks ?? []
  const sessionTrack = sessionTracks[activeTrack]
  const workoutTrack = workoutTracks[activeTrack]
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [showVolumeSlider, setShowVolumeSlider] = useState(false)
  const [volume, setVolume] = useState(1)  // volume value between 0 and 1
  const toggleVolumeSlider = () => setShowVolumeSlider(prev => !prev)

  const sessionRound = sessionTrack?.groupWorkoutSessionRounds?.[activeRound]
  const [workoutRound, setWorkoutRound] = useState<GroupWorkoutTrackRound | null>(null)

useEffect(() => {
  if (workoutTrack?.groupWorkoutTrackRounds && activeRound < workoutTrack.groupWorkoutTrackRounds.length) {
    const newRound = workoutTrack.groupWorkoutTrackRounds[activeRound]
    setWorkoutRound(newRound)
  } else {
    setWorkoutRound(null) // explicitly reset
  }
}, [workoutTrack, activeRound])

  const [roundTime, setRoundTime] = useState<number>(10)
  const [updatedRoundTime, setUpdatedRoundTime] = useState<boolean>(false)
  // Handles switch logic.
  const [currentSwitchIndex, setCurrentSwitchIndex] = useState<number>(0)
  const [switchTimeLeft, setSwitchTimeLeft] = useState<number>(0)
  const [updatedSwitchTime, setUpdatedSwitchTime] = useState<boolean>(false)
  const switchInfo = workoutRound?.isSwitchRound
    ? {
        currentSwitch: currentSwitchIndex + 1,
        totalSwitches: workoutRound.totalSwitchRounds,
      }
    : null

  const client = initApollo()
  const [serverTime, setServerTime] = useState<number | null>(null)

  const getUpdatedServerTime = async () => {
    try {
      const { data } = await client.query({
        query: SERVER_TIME,
        fetchPolicy: 'no-cache',
      })
      return data.getServerTime.serverTime
    } catch (error) {
      console.error('Error fetching server time:', error)
      return null
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume
    }
  }, [volume])

  useEffect(() => {
    const fetchServerTime = async () => {
      const time = await getUpdatedServerTime()
      setServerTime(time)
    }

    fetchServerTime()
    const intervalId = setInterval(fetchServerTime, 200)

    // Clear the interval on component unmount
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    if (workoutRound?.isSwitchRound && workoutRound?.timeInSwitchRound) {
      setSwitchTimeLeft(workoutRound.timeInSwitchRound) // Reset switch time
      setCurrentSwitchIndex(0) // Start from the first switch
    }
  }, [workoutRound])

  useEffect(() => {
    if (workoutRound?.duration) {
      setRoundTime(workoutRound.duration)
    }
  }, [workoutRound?.duration])

  useEffect(() => {
    if (groupWorkout) {
      const newRounds = groupWorkout.groupWorkoutTracks
        .filter((track) => track.groupWorkoutTrackRounds.length > 0 && !track.isPreWorkout)
        .flatMap((track) =>
          track.groupWorkoutTrackRounds.map((round) => ({
            id: round.id,
            name: round.name,
            duration: round.duration,
          }))
        )

      // Update only if the rounds actually changed
      setRounds((prevRounds) => {
        const roundsChanged = JSON.stringify(prevRounds) !== JSON.stringify(newRounds)
        return roundsChanged ? newRounds : prevRounds
      })
    }
  }, [loading, groupWorkout])

  const { isPaused, handlePause } = useControls()

  const customHandlePause = () => {
    toggleShowPausePopup(true)
    handlePause()
    if (isPaused) {
      audioRef.current?.play()
      videoRef.current?.play()
    } else {
      audioRef.current?.pause()
      videoRef.current?.pause()
    }
  }

  // Handle synchronization between timer and audio playback
  useEffect(() => {
    const handleRoundComplete = () => {
      if (!workoutTrack || !groupWorkoutSession) return

      const totalRounds = workoutTrack.groupWorkoutTrackRounds.length ?? 0
      if (totalRounds === 0) return

      const isLastRound = activeRound >= totalRounds - 1
      const isPreOrPostWorkout = workoutTrack.isPreWorkout || workoutTrack.isPostWorkout
      const updatedSessionData = {
        id: groupWorkoutSession.id, // Ensure the session ID is included
        groupWorkout: { id: groupWorkoutSession.groupWorkout.id },
        groupBags: groupWorkoutSession.groupBags.map((groupbag) => ({
          id: groupbag.id,
          isEnabled: groupbag.isEnabled,
          bag: {
            id: groupbag.bag.id,
            name: groupbag.bag.name,
            reference: groupbag.bag.reference,
            rank: groupbag.bag.rank,
          },
        })),
        groupWorkoutSessionTracks: groupWorkoutSession.groupWorkoutSessionTracks.map(
          (track: { id: any; trackId: any; sortOrder: any; groupWorkoutSessionRounds: any[] }) => ({
            id: track.id,
            trackId: track.trackId,
            sortOrder: track.sortOrder,
            groupWorkoutSessionRounds: track.groupWorkoutSessionRounds.map((round) => ({
              id: round.id,
              roundId: round.roundId,
              sortOrder: round.sortOrder,
              groupWorkoutHits: round.groupWorkoutHits.map(
                (hit: { id: any; force: any; punchedAt: any; bag: { id: any } }) => ({
                  id: hit.id,
                  force: hit.force,
                  punchedAt: hit.punchedAt,
                  bag: { id: hit.bag.id },
                })
              ),
            })),
          })
        ),
      }
      // Save the session before transitioning to the next round
      saveGroupWorkoutSession(updatedSessionData)

      if (isPreOrPostWorkout) {
        // Loop within the same track
        updateActiveRound(isLastRound ? 0 : activeRound + 1)
      } else {
        const isLastTrack = activeTrack >= workoutTracks.length - 1
        if (isLastRound) {
          if (!isLastTrack) {
            updateActiveTrack(activeTrack + 1) // Move to the next track
            updateActiveRound(0) // Reset to the first round
          }
        } else {
          updateActiveRound(activeRound + 1) // Move to the next round
        }
      }
    }

    const audioElement = audioRef.current

    if (!audioElement || !workoutRound) return

    const syncTimerWithAudio = () => {
      const roundStartTime = workoutRound.calculatedAudioTime
      const timeLeft = Math.max(workoutRound.duration - (audioElement.currentTime - roundStartTime), 0)
      setRoundTime(timeLeft)
      setUpdatedRoundTime(true);

      if (timeLeft <= 0) {
        handleRoundComplete()
      }
    }

    audioElement.addEventListener('timeupdate', syncTimerWithAudio)

    return () => {
      audioElement.removeEventListener('timeupdate', syncTimerWithAudio)
    }
  }, [
    activeRound,
    activeTrack,
    groupWorkoutSession,
    saveGroupWorkoutSession,
    updateActiveRound,
    updateActiveTrack,
    workoutRound,
    workoutTrack,
    workoutTracks.length,
  ])

  useEffect(() => {
    const handleSwitchComplete = () => {
      if (!workoutRound) return

      const isLastSwitch = currentSwitchIndex >= workoutRound.totalSwitchRounds - 1
      if (isLastSwitch) {
        setSwitchTimeLeft(0) // Ensure the timer stays at 0
      } else {
        setCurrentSwitchIndex(currentSwitchIndex + 1) // Increment the switch index
        setSwitchTimeLeft(workoutRound.timeInSwitchRound) // Reset the timer for the next switch
      }
    }

    const audioElement = audioRef.current

    if (!audioElement || !workoutRound?.isSwitchRound) return

    const syncSwitchTimerWithAudio = () => {
      const switchStartTime = workoutRound.calculatedAudioTime + currentSwitchIndex * workoutRound.timeInSwitchRound // Calculate start time for the current switch
      const timeLeft = Math.max(workoutRound.timeInSwitchRound - (audioElement.currentTime - switchStartTime), 0)
      setSwitchTimeLeft(timeLeft)
      setUpdatedSwitchTime(true);
      const isLastSwitch = currentSwitchIndex >= workoutRound.totalSwitchRounds - 1
      if (!isLastSwitch && workoutRound.showSwitchScreenEncourage) {
        if (timeLeft < 1.5) {
          toggleShowSwitchPopupEncourage(true)
          setTimeout(() => {
            toggleShowSwitchPopupEncourage(false)
          }, 3000)
        }
      }
      if (!isLastSwitch && workoutRound.showSwitchScreenExercise) {
        if (timeLeft < 1.5) {
          toggleShowSwitchPopupExcercise(true)
          setTimeout(() => {
            toggleShowSwitchPopupExcercise(false)
          }, 3000)
        }
      }

      if (timeLeft === 0) {
        handleSwitchComplete() // Handle completion of the current switch
      }
    }

    audioElement.addEventListener('timeupdate', syncSwitchTimerWithAudio)

    return () => {
      audioElement.removeEventListener('timeupdate', syncSwitchTimerWithAudio)
    }
  }, [workoutRound, currentSwitchIndex])

  useEffect(() => {
    if (updatedRoundTime) {
      setUpdatedRoundTime(false)
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current)
      }
      if (!isPaused) {
        timerIntervalRef.current = setInterval(() => {
          if (!isPaused) {
            setRoundTime(prevTime => {
              const newTimeLeft = prevTime - 0.05;
              return newTimeLeft;
            });
          }
        }, 50)
      }
    }
  }, [updatedRoundTime, setRoundTime, isPaused, setUpdatedRoundTime])

  // Code for smooth timer.
  useEffect(() => {
    if (updatedSwitchTime) {
      setUpdatedSwitchTime(false)
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current)
      }
      if (!isPaused) {
        timerIntervalRef.current = setInterval(() => {
          if (!isPaused) {
            setSwitchTimeLeft(prevTime => {
              const newTimeLeft = prevTime - 0.05;
              return newTimeLeft;
            });
          }
        }, 50)
      }
    }
  }, [updatedSwitchTime, setSwitchTimeLeft, isPaused, setUpdatedSwitchTime])

  // Reset switch index and timer when the round changes
  useEffect(() => {
    if (workoutRound?.isSwitchRound) {
      setCurrentSwitchIndex(0) // Start from the first switch
      setSwitchTimeLeft(workoutRound.timeInSwitchRound) // Set the timer for the first switch
    }
  }, [workoutRound])

  // Handle next and previous round transitions
  const customHandleNext = () => {
    if (!workoutTrack) return

    const isLastRound = activeRound >= workoutTrack.groupWorkoutTrackRounds.length - 1
    if (isLastRound) {
      const isLastTrack = activeTrack >= workoutTracks.length - 1
      if (!isLastTrack) {
        const nextTrack = workoutTracks[activeTrack + 1]
        const nextRound = nextTrack.groupWorkoutTrackRounds[0]
        if (audioRef.current) {
          audioRef.current.currentTime = nextRound.calculatedAudioTime
        }
        updateActiveTrack(activeTrack + 1)
        updateActiveRound(0)
      }
    } else {
      const nextRound = workoutTrack.groupWorkoutTrackRounds[activeRound + 1]
      if (audioRef.current) {
        audioRef.current.currentTime = nextRound.calculatedAudioTime
      }
      updateActiveRound(activeRound + 1)
    }

    updateTimerAfterSkip()
  }

  const customHandlePrev = () => {
    if (activeRound > 0) {
      const prevRound = workoutTrack.groupWorkoutTrackRounds[activeRound - 1]
      if (audioRef.current) {
        audioRef.current.currentTime = prevRound.calculatedAudioTime
      }
      updateActiveRound(activeRound - 1)
    } else if (activeTrack > 0) {
      const previousTrack = workoutTracks[activeTrack - 1]
      const lastRoundIndex = previousTrack.groupWorkoutTrackRounds.length - 1
      const prevRound = previousTrack.groupWorkoutTrackRounds[lastRoundIndex]
      if (audioRef.current) {
        audioRef.current.currentTime = prevRound.calculatedAudioTime
      }
      updateActiveTrack(activeTrack - 1)
      updateActiveRound(lastRoundIndex)
    }

    updateTimerAfterSkip()
  }

  useEffect(() => {
    // Update the workoutRound and reset the timer whenever the active round or track changes
    if (workoutTrack && activeRound < workoutTrack.groupWorkoutTrackRounds?.length) {
      const newWorkoutRound = workoutTrack.groupWorkoutTrackRounds[activeRound]
      if (newWorkoutRound?.duration) {
        setRoundTime(newWorkoutRound.duration) // Reset the round timer
      }
    }
  }, [activeTrack, activeRound, workoutTrack])

  // Play Audio on Component Load
  useEffect(() => {
    if (audioRef.current && groupWorkout?.audioFile?.blobUrl) {
      audioRef.current.play().catch((err) => {
        console.error('Failed to autoplay audio:', err)
      })
    }
  }, [groupWorkout])

  const [timeLeft, setTimeLeft] = useState((groupWorkout?.duration ?? 0) * 60)

  // TODO: fix for rounds and durations
  const updateTimerAfterSkip = () => {
    if (activeTrack === null) return

    const playedTracks = groupWorkout?.groupWorkoutTracks.slice(0, activeTrack)
    const skippedTime = (playedTracks ?? []).reduce((acc, track) => acc + track.duration * 60, 0)

    setTimeLeft((prevTimeLeft) => (prevTimeLeft = (groupWorkout?.duration ?? 0) * 60 - skippedTime))
  }

  const closePopup = () => {
    toggleShowPausePopup(false)
    navigate('/group-workouts')
  }

  const closeBagPopup = () => {
    toggleShowBagPopup(false)
    handlePause()
    if (isPaused) {
      audioRef.current?.play()
      videoRef.current?.play()
    } else {
      audioRef.current?.pause()
      videoRef.current?.pause()
    }
  }

  const resumeWorkout = () => {
    toggleShowPausePopup(false)
    handlePause()
    if (isPaused) {
      audioRef.current?.play()
      videoRef.current?.play()
    } else {
      audioRef.current?.pause()
      videoRef.current?.pause()
    }
  }

  const openBags = () => {
    toggleShowBagPopup(true)
    handlePause()
    if (isPaused) {
      audioRef.current?.play()
      videoRef.current?.play()
    } else {
      audioRef.current?.pause()
      videoRef.current?.pause()
    }
  }

  const formatTime = (): string => {
    let seconds = 0
    if (groupWorkout && workoutRound) {
      seconds = groupWorkout.duration - workoutRound.calculatedAudioTime - workoutRound.duration + roundTime
    }
    const hrs = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds % 3600) / 60)
    const secs = Math.floor(seconds % 60)

    return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
  }

  return (
    <Main>
      <div className="workout-new">
        {showBagPopup && <GroupBag resumeWorkout={() => {}} closePopup={() => closeBagPopup()} />}
        {showSwitchPopupExcercise && <GroupSwitch />}
        {showSwitchPopupEncourage && <GroupSwitch isEncouragement={true}/>}
        {showPausePopup && <GroupPause resumeWorkout={() => resumeWorkout()} closePopup={() => resumeWorkout()} />}

        <div className="group-header">
          <GroupWorkoutHeader>
            <div
              style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '280px',
                textAlign: 'left',
                margin: '20px 20px 20px 50px',
                alignItems: 'start',
              }}
            >
              <div style={{ fontSize: '1.5rem', fontFamily: 'CanaroBold' }}>CURRENT SESSION</div>
              <div style={{ fontSize: '3.2rem', fontFamily: 'CanaroBold' }}>
                {groupWorkout?.duration ? formatTime() : '00:00:00'}
              </div>
            </div>
            {groupWorkout && workoutRound &&
              <NewTrackList
                groupWorkout={groupWorkout}
                activeRoundId={workoutRound.id}
                isPaused={isPaused}
                roundTime={roundTime}
              />
            }
          </GroupWorkoutHeader>
        </div>

        {/* Video for the current round */}
        <div className="group-content">
          {groupWorkout?.audioFile?.blobUrl && (
            <audio ref={audioRef} src={groupWorkout.audioFile.blobUrl} autoPlay loop controls className="hidden-audio">
              Your browser does not support the audio element.
            </audio>
          )}

          {workoutRound && workoutRound.hasQuote && (
            <div className='quote-wrapper'>
              <div className='quote-timer'>
                <CircularTimer
                  roundDuration={workoutRound.duration}
                  timeLeftInRound={roundTime}
                  timeLeftInSwitch={switchTimeLeft}
                  switchDuration={workoutRound.timeInSwitchRound}
                  isExplanation ={workoutRound.isExplanationRound}
                  isPaused={isPaused}
                  showSwitch={false}
                  size="large"
                />
              </div>

              <div className='quote-text-div'>
                <GroupQuote quote={workoutRound.quote} />
              </div>
            </div>
          )}
          {/* {workoutRound?.videoFile?.blobUrl ? (
            <div className="video-wrapper">
              <video
                ref={videoRef}
                id={`video-${workoutRound.id}`}
                src={workoutRound.videoFile.blobUrl}
                autoPlay
                loop
                muted
                playsInline
                className="fullscreen-video"
              />
            </div>
          ) : (
            <p>Loading video...</p>
          )} */}

          {/* {workoutRound.hasQuote && <GroupQuote />} */}
          {/* Show Intensity Page */}
          {workoutRound?.showIntensity && sessionRound && switchInfo && (
            <div className="intensity-template">
              {workoutRound?.videoFile?.blobUrl ? (
                <div className="video">
                  <div className="video-wrapper">
                    <video
                      ref={videoRef}
                      id={`video-${workoutRound.id}`}
                      src={workoutRound.videoFile.blobUrl}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="fullscreen-video"
                    />
                  </div>
                </div>
              ) : (
                <p className="video">Loading video...</p>
              )}

              <div className="timer">
                <CircularTimer
                  roundDuration={workoutRound.duration}
                  timeLeftInRound={roundTime}
                  timeLeftInSwitch={switchTimeLeft}
                  switchDuration={workoutRound.timeInSwitchRound}
                  isExplanation ={workoutRound.isExplanationRound}
                  isPaused={isPaused}
                  showSwitch={true}
                  size="mediumplus"
                  switchInfo={{
                    currentSwitchRound: switchInfo.currentSwitch,
                    totalSwitches: switchInfo.totalSwitches,
                  }}
                />
              </div>
              <div className="intensity">
                <div className="intensity">
                  <GroupIntensity
                    serverTime={serverTime}
                    groupBags={groupWorkoutSession?.groupBags || []}
                    groupWorkoutHits={sessionRound.groupWorkoutHits}
                    exerciseSecond={workoutRound.timeInSwitchRound - switchTimeLeft}
                  />
                </div>
              </div>
            </div>
          )}
          {/* Show Hit Challenge Page */}
          {workoutRound?.showHitScore && sessionRound && switchInfo && (
            <div className="group-hit-challenge-wrapper hit-grid-container">
              <div className="graph">
                <GroupHitChallenge
                  groupBags={groupWorkoutSession?.groupBags || []}
                  groupWorkoutHits={sessionRound.groupWorkoutHits}
                />
              </div>
              <div className="timer">
                <CircularTimer
                  size="medium"
                  roundDuration={workoutRound.duration}
                  timeLeftInRound={roundTime}
                  timeLeftInSwitch={switchTimeLeft}
                  switchDuration={workoutRound.timeInSwitchRound}
                  isExplanation ={workoutRound.isExplanationRound}
                  isPaused={isPaused}
                  showSwitch={true}
                  switchInfo={{
                    currentSwitchRound: switchInfo.currentSwitch,
                    totalSwitches: switchInfo.totalSwitches,
                  }}
                />
              </div>
            </div>
          )}

          {/* Show Group Challenge Page */}
          {workoutRound?.showGroupChallenge && sessionRound && switchInfo && (
            <div className="group-group-challenge-wrapper">
              <div className="score">
                <GroupGroupChallenge
                  groupBags={groupWorkoutSession?.groupBags || []}
                  groupWorkoutHits={sessionRound.groupWorkoutHits}
                />
              </div>
              <div className="timer">
                <CircularTimer
                    size="medium"
                    roundDuration={workoutRound.duration}
                    timeLeftInRound={roundTime}
                    timeLeftInSwitch={switchTimeLeft}
                    switchDuration={workoutRound.timeInSwitchRound}
                    isExplanation ={workoutRound.isExplanationRound}
                    isPaused={isPaused}
                    showSwitch={true}
                    switchInfo={{
                      currentSwitchRound: switchInfo.currentSwitch,
                      totalSwitches: switchInfo.totalSwitches,
                    }}
                  />
                </div>
            </div>
          )}
          {/* Show Single timer Page */}
          {
            workoutRound &&
            !workoutRound?.showHitScore &&
            !workoutRound?.showGroupChallenge &&
            !workoutRound?.hasQuote &&
            !workoutRound?.showIntensity &&
            (!workoutRound?.isSwitchRound || !switchInfo ? (
              <div className="fullscreen-timer">
                <div className="timer">
                  <CircularTimer
                    size="large"
                    roundDuration={workoutRound.duration}
                    timeLeftInRound={roundTime}
                    isPaused={isPaused}
                    isExplanation ={workoutRound.isExplanationRound}
                    showSwitch={false}
                  />
                </div>
              </div>
            ) : (
              <div className="fullscreen-timer">
                <div className="timer">
                  <CircularTimer
                      size="large"
                      roundDuration={workoutRound.duration}
                      timeLeftInRound={roundTime}
                      timeLeftInSwitch={switchTimeLeft}
                      switchDuration={workoutRound.timeInSwitchRound}
                      isExplanation ={workoutRound.isExplanationRound}
                      isPaused={isPaused}
                      showSwitch={true}
                      switchInfo={{
                        currentSwitchRound: switchInfo.currentSwitch,
                        totalSwitches: switchInfo.totalSwitches,
                      }}
                    />
                  </div>
                </div>
            ))
}
          {/* {workoutTrack && !workoutRound?.isSwitchRound ? (
            <div className="round-info">
              <h2>
                {workoutTrack.name}
                {workoutTrack.isPreWorkout && ' (Pre-Workout)'}
                {workoutTrack.isPostWorkout && ' (Post-Workout)'}
              </h2>
              <h3>Round: {workoutRound?.name}</h3>
              <p>Time Remaining: {roundTime}</p>
              <CircularTimer
                size="small"
                roundDuration={workoutRound.duration}
                timeLeftInRound={roundTime}
                isPaused={isPaused}
                showSwitch={false}
              />
              {workoutRound?.isSwitchRound && switchInfo && (
                <div className="switch-timer">
                  <h3>
                    Switch {currentSwitchIndex + 1} of {workoutRound.totalSwitchRounds}
                  </h3>
                  <p>Time Remaining in Switch: {switchTimeLeft}</p>
                  <CircularTimer
                    size="small"
                    roundDuration={workoutRound.duration}
                    timeLeftInRound={roundTime}
                    timeLeftInSwitch={switchTimeLeft}
                    switchDuration={workoutRound.timeInSwitchRound}
                    isPaused={isPaused}
                    showSwitch={true}
                    switchInfo={{
                      currentSwitchRound: switchInfo.currentSwitch,
                      totalSwitches: switchInfo.totalSwitches,
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <p>Loading...</p>
          )} */}

          <HitSimulate />
        </div>

        <div className="group-controls">
          {showVolumeSlider && (
            <div
              className="volume-slider-container"
              style={{
                position: 'absolute',
                bottom: '100px',
                right: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                padding: '10px',
                borderRadius: '5px',
                width: '400px',
                zIndex: 1000,
              }}
            >
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => setVolume(Number(e.target.value))}
              />
            </div>
          )}
          <ControlsFooter
            bags={groupWorkoutSession?.groupBags.filter((b) => b.isEnabled).length || 0}
            openBagsPopup={() => openBags()}
            onVolumeClick={toggleVolumeSlider}
          >
            <Controls handlePause={customHandlePause} handlePrev={customHandlePrev} handleNext={customHandleNext} />
          </ControlsFooter>
        </div>
      </div>
    </Main>
  )
}

export default GroupWorkout
