const ArrowIcon = ({ isDark = false }: { isDark?: boolean }) => (
  <svg width="4%" height="4%" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.375 14.4375L17.375 24.4375C16.625 25.25 15.3125 25.25 14.5625 24.4375C13.75 23.6875 13.75 22.375 14.5625 21.625L21.125 15H2C0.875 15 0 14.125 0 13C0 11.9375 0.875 11 2 11H21.125L14.5625 4.4375C13.75 3.6875 13.75 2.375 14.5625 1.625C15.3125 0.8125 16.625 0.8125 17.375 1.625L27.375 11.625C28.1875 12.375 28.1875 13.6875 27.375 14.4375Z"
      fill={`rgb(255, 255, 255, ${isDark ? '0.4' : '1'})`}
    />
  </svg>
)

export default ArrowIcon
