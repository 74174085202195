import ArrowIcon from './arrow-icon'
import BagDiagonal from './bag-diagonal'
import BellIcon from './bell-icon'
import BoxingBag from './boxing-bag'
import HandIcon from './hand-icon'
import LetterCircle from './letter-circle'
import MegaFoon from './megafoon'
import PushUpIcon from './push-up-icon'
import './style.scss'

interface GroupSwitchProps {
  isEncouragement?: boolean
}

const GroupSwitch = (props: GroupSwitchProps) => {
  return (
    <div className="popupBackground">
      <div className="popupWrapper popup-container" style={{ maxWidth: '80vw', width: 'fit-content' }}>
        <BellIcon height='8%' width='8%' />
        <h1 style={{ fontSize: '6rem', padding: '2rem' }}>SWITCH!</h1>
        <div className="letter-container">
          <LetterCircle letter={'C'} isDark={true} />
          <ArrowIcon isDark={true} />
          <LetterCircle letter={'A'} />
          <ArrowIcon />
          <LetterCircle letter={'B'} />
          <ArrowIcon />
          <LetterCircle letter={'C'} />
          <ArrowIcon isDark={true} />
          <LetterCircle letter={'A'} isDark={true} />
        </div>
        <div className="action-container">
          <div>
            <div
              className="action"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}
            >
              <div>
                <HandIcon />
              </div>
              <div style={{ height: '100%', alignContent: 'center' }}>
                <BagDiagonal />
              </div>
            </div>
            <div className="label">Hold The Boxing Bag</div>
          </div>
          <div>
            <div
              className="action middle"
              style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div style={{ alignContent: 'center', height: '250px' }}>
                <BoxingBag color="white" />
              </div>
            </div>
            <div className="label">Start Boxing</div>
          </div>

          {props.isEncouragement ? (
            <div>
              <div className="action" style={{ height: '300px', alignContent: 'center' }}>
                <div>
                  <MegaFoon />
                </div>
              </div>
              <div className="label">Encourage</div>
            </div>
          ) : (
            <div>
              <div className="action" style={{ height: '300px', alignContent: 'center' }}>
                <div>
                  <PushUpIcon />
                </div>
              </div>
              <div className="label">Exercise</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GroupSwitch
