import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/button'

import { pathsObj } from 'lib/constants/paths'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import { usePause, useSetPopup } from 'store/config/selectors'

const QuitWorkoutPopup = () => {
  const navigate = useNavigate()
  const { closePopup } = useSetPopup()
  const { unpause } = usePause()

  const handleContinue = () => {
    unpause()
    closePopup()
  }

  const exitWorkout = () => {
    unpause()
    closePopup()
    navigate(pathsObj.main.home)
  }

  return (
    <PopupWrapper customCloseEvent={handleContinue}>
      <div className="PopupQuitWorkout">
        <h2>Quit workout</h2>
        <h3 className="light">Are you sure that you want to quit the workout?</h3>
        <Button customClickEvent={exitWorkout} color="light">
          <h3>Yes, quit</h3>
        </Button>
        <Button customClickEvent={handleContinue}>
          <h3>No, don't quit</h3>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default QuitWorkoutPopup
