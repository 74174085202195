import React from 'react'
import HalfCircleProgress from './half-circle-progress'
import { INRWorkoutSessionHit } from 'interfaces/workout'
import { GroupWorkoutHit, GroupBag } from 'interfaces/group-workout'
import { getEvaluatedIntensity, getHitsLastTenSeconds } from 'lib/intensity'
import './group-intensity.scss'
import GroupFireIcon from './group-fire-icon'

const colors: string[] = [
  '#2C89C8',
  '#70ED99',
  '#FF7733',
  '#7E68AE',
  '#F7D126',
  '#F04D26',
  '#00AFB9',
  '#7857FF',
  '#F9383B',
  '#006BE8',
]

interface GroupIntensityProps {
  groupBags: GroupBag[]
  groupWorkoutHits: GroupWorkoutHit[]
  weight?: number
  exerciseSecond: number
  serverTime: number | null
}

const GroupIntensity: React.FC<GroupIntensityProps> = ({
  groupBags,
  groupWorkoutHits,
  weight = 75,
  exerciseSecond,
  serverTime,
}) => {
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id
    if (!acc[bagId]) {
      acc[bagId] = []
    }
    const sessionHit: INRWorkoutSessionHit = {
      angle: 0, // Default value
      force: hit.force,
      height: 0, // Default value
      punchedAt: hit.punchedAt,
    }
    acc[bagId].push(sessionHit)
    return acc
  }, {} as Record<string, INRWorkoutSessionHit[]>)

  // Calculate intensity for each bag
  const bagIntensities = groupBags
  .map((groupBag, index) => {
    if (!groupBag.isEnabled) {
      return null;
    }
    const bagId = groupBag.bag.id;
    const hitsForBag = hitsByBag[bagId] || [];
    const currentTime: number =
      serverTime !== null ? new Date(serverTime).getTime() : new Date().getTime();
    let lastHitSeconds = 10000;
    if (exerciseSecond < 10) {
      lastHitSeconds = exerciseSecond * 1000;
    }
    const hitsLastTenSeconds = getHitsLastTenSeconds(
      hitsForBag,
      currentTime,
      lastHitSeconds
    );
    const intensity = getEvaluatedIntensity(
      hitsLastTenSeconds,
      weight,
      exerciseSecond
    );
    return {
      bagId,
      intensity,
      hitsCount: hitsForBag.length,
      gradientStart: colors[index], // Uses the original index
      gradientEnd: '#ffffff',
    };
  })
  .filter((bag) => bag !== null);

  return (
    <div className="group-intensity-container">
      <div className="group-intensity-panel">
        <div className="group-intensity-header">
          <h2 className="group-intensity-title">
            <span className="group-intensity-icon">
              <GroupFireIcon/>
            </span>
            Intensity
          </h2>
        </div>
        <div className="group-intensity-meter-container">
          {bagIntensities.map(({ bagId, intensity, gradientStart, gradientEnd }) => (
            <div key={bagId} className="group-intensity-meter">
              {/* <HalfCircleProgress
                value={intensity}
                maxValue={7}
                gradientStart={gradientStart}
                gradientEnd={gradientEnd}
                uniqueId={`gradient-${bagId}`}
              /> */}
              <HalfCircleProgress
                value={intensity}
                halfCircleSize={170}
                strokeWidth={11}
                extraStroke={11}
                numberCircleRadius={31}
                // numberCircleRadiusArrow={50}
                arrowDistance={-5}
                arrowSize={25}
                numberFontSize={"36px"}
                maxValue={7}
                gradientStart={gradientStart}
                gradientEnd={gradientEnd}
                uniqueId={`gradient-${bagId}`}
                verticalOffset={0}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GroupIntensity
