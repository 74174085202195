const Bag = ({ color = 'black', opacity = 1 }) => (
  <svg
    width="120"
    height="350"
    viewBox="-10 -10 124 354"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="bag-hover"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3745 77.8874C99.7206 78.5295 99.8663 79.2172 99.8481 79.8958H99.8436C101.907 82.4189 103.15 85.6342 103.15 89.1273V118.502C103.15 119.263 102.531 119.882 101.77 119.882H1.37995C0.619383 119.882 0 119.263 0 118.502V89.1228C0 85.3108 1.48014 81.8314 3.89391 79.2218C3.95767 78.7663 4.0943 78.3155 4.32657 77.8874L39.6633 12.1007C39.8728 11.7045 40.1415 11.3675 40.4511 11.076C40.5104 4.95051 45.6112 0 51.6319 0C57.6527 0 62.5576 4.75923 62.8036 10.7162C63.3045 11.0669 63.7326 11.5269 64.0378 12.1007L99.3745 77.8874ZM15.2506 74.5027C26.0251 71.4929 37.0774 69.8399 48.1615 69.5394V21.8105C46.8499 21.3824 45.6385 20.7265 44.591 19.8749L15.2506 74.5027ZM54.993 69.5303C66.0872 69.7853 77.1541 71.3929 87.9478 74.3669L88.4578 74.5081L58.9734 19.6199C57.8257 20.6172 56.4731 21.3778 54.993 21.8469V69.5303ZM1.37995 127.907C0.619383 127.907 0 128.526 0 129.287V133.837H101.775C102.535 133.837 103.155 133.217 103.155 132.457V129.292C103.155 128.531 102.535 127.912 101.775 127.912H1.37995V127.907ZM0 143.237V282.452C0 305.774 15.8535 326.114 38.47 331.802L39.8454 332.148C48.1388 334.234 56.8238 334.198 65.0989 332.039C87.5106 326.196 103.15 305.957 103.15 282.794V143.232C103.15 142.472 102.531 141.852 101.77 141.852H1.37995C0.619383 141.852 0 142.472 0 143.232V143.237Z"
      fill={color}
      fillOpacity={opacity}
    />
  </svg>
)

export default Bag
