import React, { useEffect, useState, useRef } from 'react'
import './circular-timer.scss'
import RoundBellIcon from './round-bell-icon'

interface CircularTimerProps {
  roundDuration: number // Total duration of the round in seconds
  timeLeftInRound: number // Remaining time in the round in seconds
  switchDuration?: number // Total duration of the switch in seconds
  timeLeftInSwitch?: number // Remaining time in the switch in seconds
  isPaused: boolean // Pause state
  size?: 'small' | 'medium' | 'mediumplus' | 'mediumlarge' | 'large' | number // Timer size
  showSwitch?: boolean // Show switch info
  switchInfo?: { currentSwitchRound: number; totalSwitches: number } // Switch details
  isExplanation?: boolean // Will change the gradient
}

const CircularTimer: React.FC<CircularTimerProps> = ({
  roundDuration,
  timeLeftInRound,
  switchDuration,
  timeLeftInSwitch,
  isPaused,
  size = 'medium',
  showSwitch = false,
  switchInfo,
  isExplanation = false,
}) => {
  const setTimeLeftInSwitch = timeLeftInSwitch ? timeLeftInSwitch : 0
  const setSwitchDuration = switchDuration ? switchDuration : 0
  const [roundMsLeft, setRoundMsLeft] = useState(timeLeftInRound * 1000)
  const [switchMsLeft, setSwitchMsLeft] = useState(setTimeLeftInSwitch * 1000)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const sizeMap = { small: 511, medium: 538, mediumplus: 579, mediumlarge: 660, large: 820 }
  const widthSizeMapProgressBar = { small: 322, medium: 339, mediumplus: 365, mediumlarge: 416, large: 516 };
  const heightSizeMapProgressBar = { small: 9.5, medium: 10, mediumplus: 10.8, mediumlarge: 12.3, large: 15 };
  const widthSizeMapRoundBell = { small: 56.56, medium: 59.54, mediumplus: 64.07, mediumlarge: 73.14, large: 79 };
  const heightSizeMapRoundBell = { small: 52.92, medium: 55.7, mediumplus: 59.86, mediumlarge: 68.25, large: 74 };
  const strokeWidthMap = { small: 12.35, medium: 13, mediumplus: 14, mediumlarge: 16, large: 20 };
  const extraStrokeWidthMap = { small: 31.35, medium: 33, mediumplus: 35.5, mediumlarge: 40.5, large: 50 };
  const timerSize = typeof size === 'number' ? size : sizeMap[size]

  const progressBarWidthSize = typeof size === 'number' ? size : widthSizeMapProgressBar[size]
  const progressBarHeigthSize = typeof size === 'number' ? size : heightSizeMapProgressBar[size]
  const RoundBellIconWidthSize = typeof size === 'number' ? size : widthSizeMapRoundBell[size]
  const RoundBellIconHeigthSize = typeof size === 'number' ? size : heightSizeMapRoundBell[size]
  const ExtraStrokeWidth = typeof size === 'number' ? size : extraStrokeWidthMap[size]
  const strokeWidth = typeof size === 'number' ? size : strokeWidthMap[size]
  const maxStroke = Math.max(strokeWidth + ExtraStrokeWidth, strokeWidth + strokeWidth);
  const radius = timerSize / 2 - maxStroke / 2;

  const circumference = 2 * Math.PI * radius

  const calculateOffset = (msLeft: number, totalDuration: number) => {
    const clampedMsLeft = Math.max(msLeft, 0);
    const progress = 1 - clampedMsLeft / (totalDuration * 1000);
    return Math.min(progress, 1) * circumference;
  };

  const [progressBarWidth, setProgressBarWidth] = useState(100)
  useEffect(() => {
    const newWidth = ((roundMsLeft / (roundDuration * 1000)) * 100).toFixed(2)
    setProgressBarWidth(parseFloat(newWidth)) // Update the progress dynamically
  }, [roundMsLeft, roundDuration])

  useEffect(() => {
    setSwitchMsLeft(setTimeLeftInSwitch * 1000)
  }, [timeLeftInSwitch])

  useEffect(() => {
    setRoundMsLeft(timeLeftInRound * 1000)
  }, [timeLeftInRound])

  const formatTime = (msLeft: number) => {
    const clampedMs = Math.max(msLeft, 0); // Prevent negative time
    const totalSeconds = Math.floor(clampedMs / 1000);
    const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div
      className={`circular-timer circular-timer-${size} ${showSwitch ? `switch` : `no-switch`}`}
      style={{ width: timerSize, height: timerSize }}
    >
      <div className="svg-circle">
        <svg
          width={timerSize}
          height={timerSize}
          viewBox={`0 0 ${timerSize} ${timerSize}`}
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            {isExplanation ? (
              <>
                <stop offset="0%" stopColor="#F04D26" /> 
                <stop offset="100%" stopColor="#FF7733" />
              </>
            ) : (
              <>
                <stop offset="0%" stopColor="#20ebec" />
                <stop offset="100%" stopColor="#ffbd2c" />
              </>
            )}
            </linearGradient>
          </defs>
          <circle
            className="circle-bg"
            cx={timerSize / 2}
            cy={timerSize / 2}
            r={radius}
            strokeWidth={strokeWidth + ExtraStrokeWidth}
          />
          {/* Round Progress */}
          {!showSwitch && (
            <circle
              className="circle-progress round-progress"
              cx={timerSize / 2}
              cy={timerSize / 2}
              r={radius}
              strokeWidth={strokeWidth + strokeWidth}
              strokeDasharray={circumference}
              strokeDashoffset={calculateOffset(roundMsLeft, roundDuration)}
              stroke="url(#gradient)"
            />
          )}
          {/* Switch Progress */}
          {showSwitch && (
            <circle
              className="circle-progress round-progress"
              cx={timerSize / 2}
              cy={timerSize / 2}
              r={radius}
              strokeWidth={strokeWidth + strokeWidth}
              strokeDasharray={circumference}
              strokeDashoffset={calculateOffset(switchMsLeft, setSwitchDuration)}
              stroke="url(#gradient)"
            />
          )}
        </svg>
      </div>
      <div className="timer-content">
        {!showSwitch && (
          <h1 className="timer-time">{formatTime(roundMsLeft)}</h1>
        )}
        {showSwitch && (
          <div className="switch-info">
            <h1 className="timer-time">{formatTime(switchMsLeft)}</h1>
            {switchInfo && (
              <div>
                <svg
                  width={progressBarWidthSize}
                  height={progressBarHeigthSize}
                  className="progress-bar-svg"
                  viewBox={`0 0 ${progressBarWidthSize} ${progressBarHeigthSize}`}
                >
                  <defs>
                  <linearGradient id="progress-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  {isExplanation ? (
                    <>
                      <stop offset="0%" stopColor="#FF7733" />
                      <stop offset="100%" stopColor="#F04D26" />
                    </>
                  ) : (
                    <>
                      <stop offset="0%" stopColor="#ffbd2c" />
                      <stop offset="100%" stopColor="#20ebec" />
                    </>
                  )}
                </linearGradient>
                    <clipPath id="progress-clip">
                      <rect
                        x="0"
                        y="0"
                        width={(progressBarWidthSize * progressBarWidth) / 100}
                        height={progressBarHeigthSize}
                        rx={progressBarHeigthSize / 2}
                        ry={progressBarHeigthSize / 2}
                      />
                    </clipPath>
                  </defs>
                  <rect
                    x="0"
                    y="0"
                    width={progressBarWidthSize}
                    height={progressBarHeigthSize}
                    fill="#333"
                    rx={progressBarHeigthSize / 2}
                    ry={progressBarHeigthSize / 2}
                  />
                  <rect
                    x="0"
                    y="0"
                    width={progressBarWidthSize}
                    height={progressBarHeigthSize}
                    fill="url(#progress-gradient)"
                    clipPath="url(#progress-clip)"
                    rx={progressBarHeigthSize / 2}
                    ry={progressBarHeigthSize / 2}
                  />
                </svg>
                <div className="info-container">
                  <RoundBellIcon height={RoundBellIconHeigthSize} width={RoundBellIconWidthSize} />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="current-round">{switchInfo.currentSwitchRound}</span>
                    <span className="total-rounds">/{switchInfo.totalSwitches}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CircularTimer
