import React, { useEffect, useRef, useState } from 'react'
import { GroupWorkout } from 'interfaces/group-workout'
import './new-track-list.scss'

interface NewTrackListProps {
  groupWorkout: GroupWorkout | null
  activeRoundId: string | null
  isPaused: boolean
  roundTime: number
}

const NewTrackList: React.FC<NewTrackListProps> = ({
  groupWorkout,
  activeRoundId,
  isPaused,
  roundTime,
}) => {
  // List of rounds for all tracks
  const [trackList, setTrackList] = useState<any[]>([])
  // This offset will be applied to the entire track list container to center the active track
  const [offset, setOffset] = useState(0)
  // This arrowOffset moves the arrow relative to the active track
  const [arrowOffset, setArrowOffset] = useState(0)

  // Ref for the track list container
  const trackListRef = useRef<HTMLDivElement>(null)
  // Array of refs for each track item
  const trackRefs = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    // Flatten the workout data to get all track rounds
    const groupWorkoutTrackList = groupWorkout?.groupWorkoutTracks.flatMap(
      (groupWorkoutTrack) => groupWorkoutTrack.groupWorkoutTrackRounds
    )
    setTrackList(groupWorkoutTrackList || [])
  }, [groupWorkout, activeRoundId])

  // Find index of the active round within the flattened track list
  const activeTrackIndex = trackList.findIndex((track) => track.id === activeRoundId)

  // This function calculates the progress for a given track
  const calculateTrackProgress = (index: number) => {
    if (index < activeTrackIndex) return 100
    if (index > activeTrackIndex) return 0
    const trackDuration = trackList[index]?.duration || 1
    return Math.min(100 - (roundTime / trackDuration) * 100, 100)
  }

  useEffect(() => {
    // If no active track or refs are not set, do nothing
    if (
      activeTrackIndex === -1 ||
      !trackListRef.current ||
      !trackRefs.current[activeTrackIndex]
    )
      return

    // Get container width
    const containerWidth = trackListRef.current.offsetWidth
    // Get the active track element and its dimensions
    const activeTrackEl = trackRefs.current[activeTrackIndex]
    const activeTrackLeft = activeTrackEl.offsetLeft
    const activeTrackWidth = activeTrackEl.offsetWidth
    // Compute the center of the active track
    const activeCenter = activeTrackLeft + activeTrackWidth / 2
    // Compute container center
    const containerCenter = containerWidth / 2
    // Adjust the track list container offset so that the active track is centered
    setOffset(containerCenter - activeCenter)

    // Calculate progress for the active track
    const calcProgress = calculateTrackProgress(activeTrackIndex)
    const progressFraction = calcProgress / 100
    // When progress is 0, arrow should be at left edge of active track.
    // When progress is 100, arrow should be at right edge.
    const newArrowOffset = -activeTrackWidth / 2 + progressFraction * activeTrackWidth
    setArrowOffset(newArrowOffset)
  }, [activeTrackIndex, trackList.length, roundTime])

  return (
    <div
      style={{
        position: 'relative', // For absolute positioning of the arrow
        textAlign: 'center',
        overflow: 'hidden',
        height: '150px',
      }}
    >
      {/* Arrow positioned above the track list */}
      <div
        className="group-workout-arrow"
        style={{
          // The arrow is fixed at the container’s center plus the dynamic offset from the active track
          transform: `translateX(calc(-50% + ${arrowOffset}px))`,
        }}
      >
        <svg width="39" height="25" viewBox="0 0 39 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.232 24C20.4622 25.3333 18.5378 25.3333 17.768 24L0.880454 -5.25001C0.110652 -6.58334 1.07291 -8.25 2.61251 -8.25L36.3875 -8.25C37.9271 -8.25 38.8893 -6.58333 38.1195 -5.25L21.232 24Z"
            fill="white"
          />
        </svg>
      </div>

      {/* Track list container */}
      <div style={{ position: 'relative', overflow: 'hidden', height: '100px' }}>
        <div
          ref={trackListRef}
          className="group-workout-animation"
          style={{
            transform: `translateX(${offset}px)`,
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            height: '100%',
            marginTop: '40px',
            position: 'relative',
            whiteSpace: 'nowrap',
          }}
        >
          {trackList.map((track, index) => {
            const progress = calculateTrackProgress(index)
            return (
              <div
                key={track.id}
                // Set a ref for this track item for measurement
                ref={(el) => {
                  if (el) trackRefs.current[index] = el
                }}
                className="track-item"
                style={{
                  flex: `${track.duration} 0 auto`,
                  minWidth: '200px',
                  height: '47px',
                  borderRadius: '100px',
                  border: '2px solid white',
                  color: 'white',
                  display: 'flex',            // Flex container for base text
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  whiteSpace: 'nowrap',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: '2%',
                  textAlign: 'center',
                  padding: '0 30px',
                }}
              >
                {track.name}
                {/* Progress overlay: white background with black text */}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '100px',
                    display: 'flex',          // Flex again for overlay text
                    alignItems: 'center',
                    justifyContent: 'center',
                    pointerEvents: 'none',
                    fontWeight: 600,
                    fontSize: '24px',
                    letterSpacing: '2%',
                    textAlign: 'center',
                    // As progress increases, more of this overlay appears from the left
                    clipPath: `inset(0 ${100 - progress}% 0 0)`,
                  }}
                >
                  {track.name}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NewTrackList
