const BagDiagonal = () => (
  <svg width="101" height="212" viewBox="0 0 101 212" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2323_3131)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.1765 58.2912C89.3047 58.7055 89.3073 59.1298 89.2184 59.5267C90.1333 61.2474 90.4809 63.286 90.0682 65.3301L86.5905 82.5565C86.4997 83.006 86.0631 83.2886 85.6221 83.1996L26.742 71.3127C26.2924 71.2219 26.0098 70.7853 26.0989 70.3442L29.5766 53.1179C30.0287 50.8787 31.3048 49.0177 33.0294 47.777C33.1164 47.5209 33.256 47.2666 33.4414 47.048L61.9516 12.6479C62.1166 12.4429 62.3188 12.2718 62.5312 12.1382C63.2894 8.55724 66.8708 6.25246 70.3992 6.96479C73.9276 7.67712 76.2395 11.0481 75.6782 14.5717C75.934 14.8351 76.1252 15.1562 76.2348 15.5314L89.168 58.2895L89.1765 58.2912ZM40.2475 46.3476C46.9225 45.8591 53.5978 46.2004 60.1307 47.3339L65.7814 19.3443C65.0609 18.934 64.434 18.4102 63.9159 17.7848L40.2475 46.3476ZM64.1443 48.1354C70.6189 49.6014 76.9174 51.8528 82.8937 54.8689L83.1735 55.0137L72.386 19.3359C71.5978 19.7858 70.7097 20.0744 69.7898 20.1712L64.146 48.1269L64.1443 48.1354ZM25.7916 76.02C25.3421 75.9293 24.9122 76.2221 24.8232 76.6631L24.2838 79.3348L83.9612 91.3827C84.4107 91.4735 84.8406 91.1807 84.9296 90.7397L85.3046 88.8822C85.3954 88.4326 85.1026 88.0028 84.6615 87.9137L25.7899 76.0285L25.7916 76.02ZM32.0182 206.087C37.2174 207.18 42.5999 207.322 47.8014 206.219C56.6358 204.348 62.8834 199.986 64.9399 189.799L83.6641 97.0518C83.7549 96.6022 83.4621 96.1724 83.021 96.0833L24.141 84.1964C23.6914 84.1056 23.2616 84.3984 23.1725 84.8395L4.48938 177.384C2.29419 188.257 7.06586 194.923 15.441 200.224C19.292 202.661 23.5943 204.306 28.0471 205.25L32.0097 206.085L32.0182 206.087Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2323_3131">
        <rect width="61.7034" height="202.779" fill="white" transform="translate(40.1279 0.853516) rotate(11.4137)" />
      </clipPath>
    </defs>
  </svg>
)

export default BagDiagonal
