import React from 'react';
import { INRWorkoutSessionHit } from 'interfaces/workout';
import { GroupWorkoutHit, GroupBag } from 'interfaces/group-workout';
import { getEvaluatedIntensity } from 'lib/intensity';
import GroupFireIcon from './group-fire-icon'
import './group-group-challenge.scss'

// Extend the INRWorkoutSessionHit interface to include the timestamp
interface ExtendedINRWorkoutSessionHit extends INRWorkoutSessionHit {
  punchedAt: string; // punchedAt remains a string
  punchedAtTimestamp: number; // Add a number version of the timestamp
}

// Type for bag intensities
interface ScoresBySecond {
  [second: number]: {
    [bagId: string]: number; // Bag-specific intensity
    total: number; // Total intensity for the second
  };
}

// Props for GroupGroupChallenge component
interface GroupGroupChallengeProps {
  groupBags: GroupBag[];
  groupWorkoutHits: GroupWorkoutHit[];
  weight?: number;
}

// GroupGroupChallenge component
const GroupGroupChallenge: React.FC<GroupGroupChallengeProps> = ({ groupBags, groupWorkoutHits, weight = 75 }) => {
  // Group hits by bag
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id;
    if (!acc[bagId]) {
      acc[bagId] = [];
    }
    const sessionHit: ExtendedINRWorkoutSessionHit = {
      angle: 0, // Default value
      force: hit.force,
      height: 0, // Default value
      punchedAt: hit.punchedAt, // Keep the original string
      punchedAtTimestamp: Math.floor(Number(hit.punchedAt) / 1000), // Convert to whole seconds
    };

    acc[bagId].push(sessionHit);
    return acc;
  }, {} as Record<string, ExtendedINRWorkoutSessionHit[]>);

  // Find the minimum and maximum seconds for calculations
  const allTimestamps = groupWorkoutHits.map((hit) =>
    Math.floor(Number(hit.punchedAt) / 1000) // Convert to whole seconds
  );
  const minSecond = Math.min(...allTimestamps);
  const maxSecond = Math.max(...allTimestamps);

  // Calculate scores per second
  const scoresBySecond: ScoresBySecond = {};

  for (let currentSecond = minSecond; currentSecond <= maxSecond; currentSecond++) {
    scoresBySecond[currentSecond] = { total: 0 };

    groupBags.forEach((groupBag) => {
      const bagId = groupBag.bag.id;
      const hitsForBag = hitsByBag[bagId] || [];

      // Filter hits for the last 10 seconds including the current second
      const relevantHits = hitsForBag.filter(
        (hit) =>
          hit.punchedAtTimestamp >= currentSecond - 10 &&
          hit.punchedAtTimestamp <= currentSecond
      );

      // Calculate intensity based on relevant hits
      let excerciseSecond = currentSecond - minSecond;
      if (currentSecond - minSecond > 10) {
        excerciseSecond = 10;
      }
      let intensity = getEvaluatedIntensity(relevantHits, weight, excerciseSecond)

      // Adjust the intensity by subtracting 1
      intensity = Math.max(0, intensity - 1)

      scoresBySecond[currentSecond][bagId] = intensity
      scoresBySecond[currentSecond].total += intensity
    });
  }

  // Convert scoresBySecond to a sorted array
  const sortedScores = Object.entries(scoresBySecond)
    .map(([second, data]) => ({
      second: Number(second),
      ...data,
    }))
    .sort((a, b) => a.second - b.second);

  // Calculate cumulative total score
  const cumulativeTotalScore = sortedScores.reduce((sum, row) => sum + row.total, 0);
  // const cumulativeTotalScore = 99999
  return (
    <div>
      <h2 className="group-group-title">INTENSITY POINTS GROUP</h2>
      <h3 className="group-group-score">
        <span className="score-number">{cumulativeTotalScore}</span>
        <span className="score-icon"><GroupFireIcon /></span>
      </h3>
      {/* <table border={1} cellPadding={5}>
        <thead>
          <tr>
            <th>Second</th>
            {groupBags.map((groupBag) => (
              <th key={groupBag.bag.id}>Bag {groupBag.bag.id}</th>
            ))}
            <th>Total Score</th>
          </tr>
        </thead>
        <tbody>
          {sortedScores.map(({ second, total, ...bagScores }) => (
            <tr key={second}>
              <td>{second}</td>
              {groupBags.map((groupBag) => (
                <td key={groupBag.bag.id}>{bagScores[groupBag.bag.id] || 0}</td>
              ))}
              <td>{total}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default GroupGroupChallenge;
