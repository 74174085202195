import React from 'react'
import './style.scss'
import SkipIcon from './skip-icon'

interface ControlsProps {
  handlePause: () => void
  handlePrev: () => void
  handleNext: () => void
}

const Controls: React.FC<ControlsProps> = ({ handlePause, handlePrev, handleNext }) => {
  return (
    <div style={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
      <button
        onClick={handlePrev}
        style={{
          backgroundColor: 'black',
          height: '80px',
          width: '80px',
          borderRadius: '50%',
          cursor: 'pointer',
          border: 'none',
        }}
      >
        <SkipIcon />
      </button>
      <button onClick={handlePause} className="button-style-group button-gradient group-workout-font-family">
        PAUSE
      </button>
      <button
        onClick={handleNext}
        style={{
          backgroundColor: 'black',
          height: '80px',
          width: '80px',
          borderRadius: '50%',
          cursor: 'pointer',
          border: 'none',
        }}
      >
        <SkipIcon isLeft={false} />
      </button>
    </div>
  )
}

export default Controls
