import ButtonRow from '../group-setup/button-row'
import './style.scss'

interface PopupProps {
  closePopup: () => void
  resumeWorkout: () => void
}

const GroupPause: React.FC<PopupProps> = ({ closePopup, resumeWorkout }) => {
  return (
    <div className="popupBackground">
      <div className="popupWrapper setup-container">
          <div className="pause-container" style={{ padding: '100px' }}>
            <div style={{ position: 'relative', left: '50%', transform: 'translate(-50%, -50%)', top: '50%' }}>
              <div className="group-setup-title" style={{ fontSize: '4rem' }}>
                WORKOUT
              </div>
              <div className="group-setup-title" style={{ fontSize: '4rem' }}>
                PAUSED
              </div>
          </div>
        </div>
        <ButtonRow
          start="Keep going"
          handleSubmit={resumeWorkout}
          close="Back off"
          closePopup={closePopup}
          isSetup={false}
          showWhiteSubmit={true}
        />
      </div>
    </div>
  )
}

export default GroupPause
