// src/hooks/useGroupWorkoutQueries.ts
import { useQuery, gql, ApolloError } from '@apollo/client';
import { GroupWorkoutSession, GroupWorkoutTrack, GroupWorkoutTrackRound, DownloadLocation } from 'interfaces/group-workout';
import { useGroupWorkoutSessionActions, useGroupWorkoutSessionById, useCurrentGroupWorkoutSession } from 'store/group-workout-session/selectors';
import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';

const GET_GROUP_WORKOUT_SESSION = gql`
  query GroupWorkoutSession($id: String!) {
    groupWorkoutSession(input: { id: $id }) {
      id
      groupWorkout {
        id
      }
      groupBags {
        id
        isEnabled
        bag {
          id
          name
          reference
          rank
          latestWifiStrength
          averageReceivedPackets
          latestRebootCount
        }
      }
      groupWorkoutSessionTracks {
        id
        trackId
        sortOrder
        groupWorkoutSessionRounds {
          id
          roundId
          sortOrder
          groupWorkoutHits {
            id
            force
            punchedAt
            bag {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * Custom hook to fetch a single group workout by ID.
 * Upon successful fetch, it updates the GroupWorkoutStore.
 * Manages loading and error states via Zustand.
 */
export const useGroupWorkoutSessionByIdQuery = (
  id: string,
  options?: { skip?: boolean }
) => {
  const { setGroupWorkoutSessionById, setLoading, setError } = useGroupWorkoutSessionActions();
  const existingWorkoutSession = useGroupWorkoutSessionById(id); // Ensure this Hook is correctly defined
  // console.log('existing', existingWorkout);
  // console.log('skip', !id || !!existingWorkout || options?.skip);
  const currentWorkoutSession = useCurrentGroupWorkoutSession();
  // console.log('current', currentWorkout);
  if (existingWorkoutSession && (!currentWorkoutSession || currentWorkoutSession.id !== existingWorkoutSession.id)) {
    // console.log("setting current group workout")
    setGroupWorkoutSessionById(existingWorkoutSession);
  }
  // Initiate side effects
  const { data, loading, error } = useQuery<{ groupWorkoutSession: GroupWorkoutSession[] }>(GET_GROUP_WORKOUT_SESSION, {
    variables: { id },
    onCompleted: (data) => {
      if (data?.groupWorkoutSession && data.groupWorkoutSession.length > 0) {
        setGroupWorkoutSessionById(data.groupWorkoutSession[0]);
      }
      setLoading(false);
    },
    onError: (error: ApolloError) => {
      console.error(`Error fetching group workout session with ID ${id}:`, error.message);
      setError(error.message);
      setLoading(false);
    },
    skip: !id || !!existingWorkoutSession || options?.skip,
  });

  useEffect(() => {
    setLoading(loading);
    if (error) {
      setError(error.message);
    }
  }, [loading, error, setLoading, setError]);

  return null;
};
