import LoadingSpinner from 'components/group-workout/loading-spinner'
import { useCurrentGroupWorkoutSession, useGroupWorkoutSessionActions } from 'store/group-workout-session/selectors'
import DynamicLink from './link'
import Bag from './bag'
import { getCurrentConnectionIssue } from 'lib/connection/connection'
import { ConnectionStates } from 'interfaces/connection'

const colors: string[] = [
  '#2C89C8',
  '#70ED99',
  '#FF7733',
  '#7E68AE',
  '#F7D126',
  '#F04D26',
  '#00AFB9',
  '#7857FF',
  '#F9383B',
  '#006BE8',
]

const BagSetup = () => {
  const { toggleGroupBagEnabled } = useGroupWorkoutSessionActions()

  // Access groupWorkoutSession data from Zustand store via selectors
  const groupWorkoutSession = useCurrentGroupWorkoutSession()
  return (
    <div>
      {!groupWorkoutSession ? (
        <div style={{ padding: '2rem 0' }}>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            padding: '2rem 0',
            maxWidth: '80vw',
            width: 'fit-content',
          }}
        >
          {groupWorkoutSession.groupBags.map((bagData, index) => {
            // Extract connection data from the nested bag object, defaulting to null if undefined.
            const connectionStatusResult = getCurrentConnectionIssue({
              latestWifiStrength: bagData?.bag?.latestWifiStrength ?? null,
              averageReceivedPackets: bagData?.bag?.averageReceivedPackets ?? null,
              latestRebootCount: bagData?.bag?.latestRebootCount ?? null,
            })

            // Determine if connection is good.
            // Adjust the value 'GOOD' based on your actual good status value.
            const isConnected = connectionStatusResult.status === ConnectionStates.GOOD

            return (
              <div key={bagData.id + 'group'} style={{ textAlign: 'center' }}>
                <div onClick={() => toggleGroupBagEnabled(bagData.id)} key={bagData.id + 'container'}>
                  <Bag
                    key={bagData.id + 'bag'}
                    color={colors[index]}
                    opacity={bagData.isEnabled ? 1 : 0.4}
                  />
                </div>
                {/* The DynamicLink now directly uses the computed connection status */}
                <div
                  style={{
                    display: 'flex',
                    justifySelf: 'center',
                    WebkitJustifyContent: 'center',
                    maxHeight: '1rem',
                    minHeight: '1rem',
                  }}
                >
                  <DynamicLink key={index + 'link'} connected={isConnected} />
                </div>
                {/* Display the connection status */}
                {/* <div style={{ marginTop: '0.5rem' }}>
                  <p>Status: {connectionStatusResult.status}</p>
                  <p>Issue: {connectionStatusResult.issue}</p>
                </div> */}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default BagSetup
