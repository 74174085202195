// src/hooks/useGroupWorkoutQueries.ts
import { useQuery, gql, ApolloError } from '@apollo/client'
import { GroupWorkout, GroupWorkoutTrack, GroupWorkoutTrackRound, DownloadLocation } from 'interfaces/group-workout'
import { useGroupWorkoutActions, useGroupWorkoutById, useCurrentGroupWorkout } from 'store/group-workout/selectors'
import { downloadFileWithProgress, downloadAndAssign } from 'lib/download-file'
import { useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash-es'

// ============== QUERIES ==============
const GET_GROUP_WORKOUTS = gql`
  query GetGroupWorkouts {
    groupWorkout {
      id
      name
      duration
      description
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
      audioFile {
        id
        originalName
        downloadLocation
      }
      groupWorkoutTracks {
        id
        name
        sortOrder
        duration
        isPreWorkout
        groupWorkoutTrackRounds {
          id
          name
          sortOrder
          duration
          calculatedAudioTime
          videoFile {
            id
            originalName
            downloadLocation
          }
          hasQuote
          quote
          isSwitchRound
          isExplanationRound
          totalSwitchRounds
          timeInSwitchRound
          showIntensity
          showHitScore
          showGroupChallenge
          showGroupLeaderboard
          showSwitchScreenExercise
          showSwitchScreenEncourage
        }
      }
    }
  }
`

const GET_GROUP_WORKOUT = gql`
  query GetGroupWorkout($id: String!) {
    groupWorkout(input: { id: $id }) {
      id
      name
      duration
      description
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
      audioFile {
        id
        originalName
        downloadLocation
      }
      groupWorkoutTracks {
        id
        name
        sortOrder
        duration
        isPreWorkout
        groupWorkoutTrackRounds {
          id
          name
          sortOrder
          duration
          calculatedAudioTime
          videoFile {
            id
            originalName
            downloadLocation
          }
          hasQuote
          quote
          isSwitchRound
          isExplanationRound
          totalSwitchRounds
          timeInSwitchRound
          showIntensity
          showHitScore
          showGroupChallenge
          showGroupLeaderboard
          showSwitchScreenExercise
          showSwitchScreenEncourage
        }
      }
    }
  }
`

export const useGroupWorkoutsQuery = (): void => {
  const { setGroupWorkouts, setLoading, setError } = useGroupWorkoutActions()

  const { data, loading, error } = useQuery<{ groupWorkout: GroupWorkout[] }>(GET_GROUP_WORKOUTS, {
    onCompleted: (data) => {
      // Define an asynchronous function to process group workouts
      const processGroupWorkouts = async () => {
        try {
          // Use Promise.all with map to handle asynchronous downloads concurrently
          const updatedGroupWorkouts = await Promise.all(
            data.groupWorkout.map(async (groupWorkout) => {
              // Create new sorted tracks array without mutating the original data
              const updatedTracks = groupWorkout.groupWorkoutTracks.map((track) => ({
                ...track,
                groupWorkoutTrackRounds: track.groupWorkoutTrackRounds
                  ? [...track.groupWorkoutTrackRounds].sort((a, b) => a.sortOrder - b.sortOrder)
                  : track.groupWorkoutTrackRounds,
              }));

              // Build the updated group workout with the updated tracks
              let updatedGroupWorkout = {
                ...groupWorkout,
                groupWorkoutTracks: updatedTracks,
              };

              const downloadLocation = groupWorkout.thumbnailFile?.downloadLocation;
              if (downloadLocation) {
                try {
                  const downloadedFile = await downloadFileWithProgress(downloadLocation, () => {});
                  if (downloadedFile) {
                    updatedGroupWorkout = {
                      ...updatedGroupWorkout,
                      thumbnailFile: {
                        ...groupWorkout.thumbnailFile,
                        blobUrl: downloadedFile.blobUrl,
                      },
                    };
                  }
                } catch (downloadError) {
                  console.error('Error downloading file:', downloadError);
                }
              }
              return updatedGroupWorkout;
            })
          );

          // Update the state with the new groupWorkouts array
          setGroupWorkouts(updatedGroupWorkouts)
        } catch (processingError) {
          console.error('Error processing group workouts:', processingError)
          setError('Failed to process group workouts.')
        } finally {
          setLoading(false)
        }
      }

      // Invoke the asynchronous processing function
      processGroupWorkouts()
    },
    onError: (error: ApolloError) => {
      console.error('Error fetching group workouts:', error.message)
      setError(error.message)
      setLoading(false)
    },
  })

  useEffect(() => {
    setLoading(loading)
    if (error) {
      setError(error.message)
    }
    // Only set group workouts if data changes and it's not handled in onCompleted
    // However, since we're handling it in onCompleted, you might not need to set it here
  }, [loading, error, setLoading, setError])
}

/**
 * Custom hook to fetch a single group workout by ID.
 * Upon successful fetch, it updates the GroupWorkoutStore.
 * Manages loading and error states via Zustand.
 */
export const useGroupWorkoutByIdQuery = (id: string, options?: { skip?: boolean }) => {
  const { setGroupWorkoutById, setLoading, setError } = useGroupWorkoutActions()
  const existingWorkout = useGroupWorkoutById(id)
  const currentWorkout = useCurrentGroupWorkout()

  // Add state to track progress for audio and video downloads
  const [audioProgress, setAudioProgress] = useState<number>(0)
  const [videoProgress, setVideoProgress] = useState<number>(0)

  if (existingWorkout && (!currentWorkout || currentWorkout.id !== existingWorkout.id)) {
    setGroupWorkoutById(existingWorkout)
  }

  const { data, loading, error } = useQuery<{ groupWorkout: GroupWorkout[] }>(GET_GROUP_WORKOUT, {
    variables: { id },
    onCompleted: (data) => {
      if (data?.groupWorkout && data.groupWorkout.length > 0) {
        const workout = data.groupWorkout[0];
        for (let i = 0; i < workout.groupWorkoutTracks.length; i++) {
          const track = workout.groupWorkoutTracks[i];
          if (track.groupWorkoutTrackRounds && track.groupWorkoutTrackRounds.length > 0) {
            track.groupWorkoutTrackRounds.sort((a, b) => a.sortOrder - b.sortOrder);
          }
        }
        setGroupWorkoutById(workout)
      }
      setLoading(false)
    },
    onError: (error: ApolloError) => {
      console.error(`Error fetching group workout with ID ${id}:`, error.message)
      setError(error.message)
      setLoading(false)
    },
    skip: !id || !!existingWorkout || options?.skip,
  })

  // Ref to store the current workout ID for consistency during download
  const currentWorkoutIdRef = useRef<string | null>(null)

  useEffect(() => {
    if (currentWorkout) {
      currentWorkoutIdRef.current = currentWorkout.id

      // Trigger the download with progress callbacks for audio and video
      downloadFilesGroupWorkout(
        currentWorkout,
        currentWorkout.id,
        (progress: number) => {
          setAudioProgress(progress)
        },
        (progress: number) => {
          setVideoProgress(progress)
        }
      )
        .then((updatedWorkout) => {
          if (!isEqual(updatedWorkout, currentWorkout)) {
            // Verify if the workout is still current before updating
            if (currentWorkoutIdRef.current === updatedWorkout.id) {
              setGroupWorkoutById(updatedWorkout)
            } else {
              console.warn(`Workout ${updatedWorkout.id} is no longer the current workout. Skipping update.`)
            }
          }
        })
        .catch((downloadError) => {
          console.error('Error downloading workout files:', downloadError)
          setError('Failed to download workout files.')
        })
    }
  }, [currentWorkout, setGroupWorkoutById, setError])

  useEffect(() => {
    setLoading(loading)
    if (error) {
      setError(error.message)
    }
  }, [loading, error, setLoading, setError])

  // Optionally return the progress state so your component can render progress bars or disable actions accordingly
  return { audioProgress, videoProgress }
}

/**
 * Downloads all necessary files for a given GroupWorkout asynchronously.
 *
 * @param groupWorkout - The GroupWorkout object to process.
 * @param currentWorkoutId - The ID of the current workout to ensure consistency.
 * @returns A Promise that resolves to the updated GroupWorkout.
 */
export const downloadFilesGroupWorkout = async (
  groupWorkout: GroupWorkout,
  currentWorkoutId: string,
  onAudioProgress?: (progress: number) => void,
  onVideoProgress?: (progress: number) => void
): Promise<GroupWorkout> => {
  try {
    // Check if the workout is still the current one before proceeding
    if (groupWorkout.id !== currentWorkoutId) {
      console.warn(`Workout ${groupWorkout.id} is no longer the current workout. Aborting download.`);
      return groupWorkout;
    }

    // Process audio file
    let updatedAudioFile: DownloadLocation | null = null;
    if (groupWorkout.audioFile) {
      if (groupWorkout.audioFile.blobUrl) {
        // File already downloaded; immediately update progress to 100%
        onAudioProgress?.(100);
        updatedAudioFile = groupWorkout.audioFile;
      } else {
        updatedAudioFile = await downloadAndAssign<DownloadLocation, 'downloadLocation', 'blobUrl'>(
          groupWorkout.audioFile,
          'downloadLocation',
          'blobUrl',
          'audio',
          onAudioProgress
        );
      }
    }

    // Prepare an object to track progress for each video download.
    const videoProgresses: Record<string, number> = {};

    // Process each GroupWorkoutTrack concurrently
    const updatedGroupWorkoutTracks: GroupWorkoutTrack[] = await Promise.all(
      groupWorkout.groupWorkoutTracks.map(async (track, trackIndex) => {
        // Process each GroupWorkoutTrackRound within the track
        const updatedTrackRounds: GroupWorkoutTrackRound[] = await Promise.all(
          track.groupWorkoutTrackRounds.map(async (round, roundIndex) => {
            if (round.videoFile) {
              const uniqueKey = `${trackIndex}-${roundIndex}`;
              if (round.videoFile.blobUrl) {
                // File already downloaded; update its progress immediately
                videoProgresses[uniqueKey] = 100;
                const keys = Object.keys(videoProgresses);
                const totalProgress = keys.reduce((sum, key) => sum + videoProgresses[key], 0);
                const avgProgress = Math.round(totalProgress / keys.length);
                onVideoProgress?.(avgProgress);
                return round;
              } else {
                const updatedVideoFile = await downloadAndAssign<DownloadLocation, 'downloadLocation', 'blobUrl'>(
                  round.videoFile,
                  'downloadLocation',
                  'blobUrl',
                  'video',
                  (progress: number) => {
                    videoProgresses[uniqueKey] = progress;
                    const keys = Object.keys(videoProgresses);
                    const totalProgress = keys.reduce((sum, key) => sum + videoProgresses[key], 0);
                    const avgProgress = Math.round(totalProgress / keys.length);
                    onVideoProgress?.(avgProgress);
                  }
                );
                return {
                  ...round,
                  videoFile: updatedVideoFile,
                };
              }
            }
            return round;
          })
        );

        return {
          ...track,
          groupWorkoutTrackRounds: updatedTrackRounds,
        };
      })
    );

    return {
      ...groupWorkout,
      audioFile: updatedAudioFile,
      groupWorkoutTracks: updatedGroupWorkoutTracks,
    };
  } catch (error) {
    console.error('Error processing GroupWorkout:', error);
    throw error;
  }
};
