import { useEffect } from 'react'
import { gql, useSubscription } from '@apollo/client'

import { GroupWorkoutHit } from 'interfaces/group-workout'
import { useGroupWorkoutSessionActions } from 'store/group-workout-session/selectors'

const HIT = gql`
  subscription addGroupWorkoutHit {
    hit {
      force
      angle
      height
      punchedAt
      bag {
        id
      }
    }
  }
`

const useAddGroupHit = () => {
  const { data } = useSubscription<{ hit: GroupWorkoutHit }>(HIT)
  const { addGroupWorkoutHit } = useGroupWorkoutSessionActions()

  useEffect(() => {
    if (data && data.hit) {
      addGroupWorkoutHit(data.hit)
    } 
  }, [data, addGroupWorkoutHit])
}

export default useAddGroupHit
