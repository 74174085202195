import React, { useId } from "react";
import "./half-circle-progress.scss";

interface HalfCircleProgressProps {
  value: number; // Value between 1 and maxValue
  maxValue?: number; // Maximum value, default is 7
  arrowSize?: number; // Size of the arrow
  halfCircleSize?: number; // Overall half circle diameter (container width)
  strokeWidth?: number;
  extraStroke? : number;
  numberCircleRadius?: number; // Radius for the number circle
  numberFontSize?: string; // Font size for the number displayed in the circle
  verticalOffset?: number; // Vertical offset for the number circle relative to the meter’s fixed center
  arrowDistance?: number; // Extra distance from the number circle's edge where the arrow should be placed
  gradientStart?: string; // Start color of the gradient
  gradientEnd?: string; // End color of the gradient
  uniqueId?: string; // Unique ID to differentiate gradients
}

const HalfCircleProgress: React.FC<HalfCircleProgressProps> = ({
  value,
  maxValue = 7,
  arrowSize = 15,
  halfCircleSize = 90,
  strokeWidth = 6,
  extraStroke = 4,
  numberCircleRadius = 25,
  numberFontSize = "16px",
  verticalOffset = 0,
  arrowDistance = -10,
  gradientStart = "#20ebec",
  gradientEnd = "#ffbd2c",
  uniqueId,
}) => {
  const generatedId = useId();
  const gradientId = uniqueId || `half-circle-gradient-${generatedId}`;
  
  // For the meter, assume its "fixed" center is at:
  const centerX = halfCircleSize / 2;
  const centerY = halfCircleSize / 2; // Meter’s center remains fixed
  
  // The meter arc is drawn as a half circle.
  const radius = halfCircleSize / 2 - strokeWidth;
  const circumference = Math.PI * radius;
  
  const calculateStrokeOffset = () => {
    const progress = Math.min(value / maxValue, 1);
    return circumference * (1 - progress);
  };
  
  // Our number circle is drawn with its center at (centerX, centerY + verticalOffset)
  // We want the arrow to follow the circumference of the number circle.
  // To do that, we find the intersection of the ray from the fixed center (centerX, centerY)
  // in the direction corresponding to the intensity value with the number circle.
  // The number circle is centered at (centerX, centerY + verticalOffset) with radius Rn.
  // For a given angle theta, the ray from (centerX, centerY) is:
  //   P(t) = (centerX + t*cos(theta), centerY + t*sin(theta))
  // We solve for t such that the distance from P(t) to the number circle’s center equals Rn.
  // That is, we need t such that:
  //    (t*cos(theta))^2 + (t*sin(theta) - verticalOffset)^2 = numberCircleRadius^2.
  // Solving the quadratic (and choosing the positive solution) gives:
  //    t = sin(theta)*verticalOffset + sqrt(numberCircleRadius^2 - verticalOffset^2*cos^2(theta)).
  
  const calculateArrowTransform = () => {
    // For the half circle meter, map value to an angle between 0 and π.
    // Subtract π so that 0 intensity corresponds to the top.
    const theta = (Math.PI * value) / maxValue - Math.PI;
    // Compute t as the distance from the fixed center to the intersection with the number circle.
    const Rn = numberCircleRadius;
    const discriminant = Rn * Rn - verticalOffset * verticalOffset * Math.cos(theta) * Math.cos(theta);
    // Guard against small negative due to precision:
    const t = Math.sin(theta) * verticalOffset + Math.sqrt(Math.max(0, discriminant));
    // Now, add any extra arrowDistance and half the arrow size to push the arrow outward.
    const effectiveArrowRadius = t + arrowDistance + arrowSize / 2;
    // The arrow is placed relative to the fixed meter center.
    const x = centerX + effectiveArrowRadius * Math.cos(theta);
    const y = centerY + effectiveArrowRadius * Math.sin(theta);
    // Rotate the arrow so that it points outward.
    const rotation = (theta * 180) / Math.PI - 270;
    return { x, y, rotation };
  };
  
  const arrowTransform = calculateArrowTransform();
  const numberCircleDiameter = numberCircleRadius * 2;
  
  return (
    <div
      className="half-circle-progress"
      style={{ width: halfCircleSize, height: halfCircleSize / 2 }}
    >
      <svg
        width={halfCircleSize}
        height={halfCircleSize / 2 + 10} // Extra height for spacing
        viewBox={`0 0 ${halfCircleSize} ${halfCircleSize / 2 + 10}`}
      >
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientStart} />
            <stop offset="100%" stopColor={gradientEnd} />
          </linearGradient>
        </defs>
        {/* Background Arc */}
        <path
          d={`M ${strokeWidth},${radius + strokeWidth} 
              A ${radius},${radius} 0 0,1 ${halfCircleSize - strokeWidth},${radius + strokeWidth}`}
          stroke="#000000"
          strokeWidth={strokeWidth + extraStroke}
          fill="none"
        />
        {/* Progress Arc */}
        <path
          d={`M ${strokeWidth},${radius + strokeWidth} 
              A ${radius},${radius} 0 0,1 ${halfCircleSize - strokeWidth},${radius + strokeWidth}`}
          stroke={`url(#${gradientId})`}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={calculateStrokeOffset()}
          strokeLinecap="round"
        />
      </svg>
      {/* Arrow positioned along the number circle's circumference */}
      <div
        className="arrow"
        style={{
          position: "absolute",
          top: `${arrowTransform.y}px`,
          left: `${arrowTransform.x}px`,
          transform: `translate(-50%, -50%) rotate(${arrowTransform.rotation}deg)`,
          width: `${arrowSize}px`,
          height: `${arrowSize}px`,
        }}
      >
        <svg
          width={arrowSize}
          height={(arrowSize / 22) * 16}
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.9844 16L12.9009 1.043C12.0595 -0.347665 9.94051 -0.347665 9.09915 1.043L0 16C3.34986 14.491 7.05807 13.5737 11 13.5737C14.9419 13.5737 18.6501 14.491 22 16H21.9844Z"
            fill="#fff"
          />
        </svg>
      </div>
      {/* Number Circle centered at (centerX, centerY + verticalOffset) */}
      <div
        className="number-circle"
        style={{
          position: "absolute",
          top: `${centerY + verticalOffset}px`,
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: numberCircleDiameter,
          height: numberCircleDiameter,
        }}
      >
        <svg
          width={numberCircleDiameter}
          height={numberCircleDiameter}
          viewBox={`0 0 ${numberCircleDiameter} ${numberCircleDiameter}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={numberCircleRadius}
            cy={numberCircleRadius}
            r={numberCircleRadius - 1}
            fill="#1E1E1E"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            fontSize: numberFontSize,
            fontWeight: "bold",
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

export default HalfCircleProgress;
