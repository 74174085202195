const RoundBellIcon = ({height, width}:{height: number, width: number}) => (
  <svg width={width} height={height} viewBox="0 0 80 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2222_2602)">
      <path
        d="M32.5485 64.9662C50.1684 64.9662 64.501 50.5547 64.501 32.8393C64.501 15.1239 50.1684 0.712402 32.5485 0.712402C14.9287 0.712402 0.597656 15.1255 0.597656 32.8393C0.597656 50.5531 14.9303 64.9662 32.5485 64.9662ZM32.5485 25.0805C36.813 25.0805 40.2827 28.5612 40.2827 32.8409C40.2827 37.1206 36.813 40.6013 32.5485 40.6013C28.2841 40.6013 24.8144 37.1206 24.8144 32.8409C24.8144 28.5612 28.2841 25.0805 32.5485 25.0805ZM32.5485 37.9068C35.322 37.9068 37.577 35.6335 37.577 32.8393C37.577 30.0451 35.322 27.7734 32.5485 27.7734C29.7751 27.7734 27.5217 30.0467 27.5217 32.8393C27.5217 35.6319 29.7767 37.9068 32.5485 37.9068ZM53.4281 62.9019H50.0666C44.8104 66.0064 38.8063 67.6607 32.5485 67.6607C26.2908 67.6607 20.2867 66.0064 15.0321 62.9019H11.7159C10.1118 62.9019 8.80506 64.2186 8.80506 65.8392V71.7298C8.80506 73.3504 10.1102 74.6687 11.7159 74.6687H53.4281C55.0321 74.6687 56.3389 73.352 56.3389 71.7298V65.8392C56.3389 64.2202 55.0337 62.9019 53.4281 62.9019ZM79.6527 14.595C79.6527 11.9037 77.4672 9.71237 74.7825 9.71237C72.0979 9.71237 69.9124 11.9021 69.9124 14.595C69.9124 17.2879 72.0963 19.4776 74.7825 19.4776H74.7874C77.4365 33.3152 75.3333 45.5531 68.5264 55.873C65.9064 59.8456 63.0392 62.7749 60.8198 64.717C60.1639 65.2909 59.563 65.7781 59.0429 66.1816C57.804 67.1414 57.0189 67.6173 56.9963 67.6302C56.5731 67.8826 56.3373 68.3279 56.3373 68.7861C56.3373 69.0208 56.4003 69.2588 56.5279 69.4742C56.7799 69.897 57.2289 70.1318 57.6909 70.1318C57.9267 70.1318 58.1658 70.0707 58.3823 69.942C58.4291 69.9131 58.6617 69.7748 59.0429 69.5192C60.865 68.2974 66.0921 64.4276 70.7135 57.4646C73.9894 52.5289 76.2751 47.0563 77.5076 41.1994C78.9775 34.201 78.9403 26.6432 77.3945 18.7139C78.7513 17.8474 79.6527 16.3249 79.6527 14.595Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2222_2602">
        <rect width="79.055" height="73.9547" fill="white" transform="translate(0.597656 0.712402)" />
      </clipPath>
    </defs>
  </svg>
)

export default RoundBellIcon
