import BagSetup from '../group-setup/bag-setup'
import ButtonRow from '../group-setup/button-row'
import './style.scss'

interface PopupProps {
  closePopup: () => void
  resumeWorkout: () => void
}

const GroupBag: React.FC<PopupProps> = ({ closePopup, resumeWorkout }) => {
  return (
    <div className="popupBackground">
      <div className="popupWrapper setup-container" style={{ maxWidth: '80vw', width: 'fit-content' }}>
        <h2 style={{textAlign: 'center'}}>ADD OR REMOVE BOXING BAGS</h2>
        <div style={{ maxWidth: '80vw', width: 'fit-content', padding: '2rem 0' }}>
          <BagSetup />
        </div>
        <ButtonRow
          start="Keep going"
          handleSubmit={resumeWorkout}
          close="Back"
          closePopup={closePopup} 
          isSetup={false}
          isBags={true}
        />
      </div>
    </div>
  )
}

export default GroupBag
