const LetterCircle = ({ letter, isDark = false }: { letter: string; isDark?: boolean }) => (
  <svg width="11%" height="11%" viewBox="0 0 50 50">
    <circle cx="25" cy="25" r="20" fill={`rgb(255, 255, 255, ${isDark ? '0.4' : '1'})`} />
    <text x="25" y="35" textAnchor="middle" fontSize="30" fill={`rgb(0, 0, 0, ${isDark ? '0.4' : '1'})`} fontFamily="CanaroBold">
      {letter.toUpperCase()}
    </text>
  </svg>
)

export default LetterCircle
